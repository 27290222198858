import Vue from 'vue' //引入Vue
import VueRouter from 'vue-router' //引入vue-router
import H5Header from '@/views/h5Header'
import http from "../api/http.js"
import Cookies from 'js-cookie'

let pageUrl = H5Header
let flag = navigator.userAgent.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
if (flag) {
	// console.log("移动端");
} else {
	// console.log("pc端");
}

function getUrlParam(url, name) {
	try {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
		var r = url.split('?')[1].match(reg);
		if (r != null) {
			return r[2];
		}
		return ""; //如果此处只写return;则返回的是undefined
	} catch (e) {
		return ""; //如果此处只写return;则返回的是undefined
	}
};


Vue.use(VueRouter)
const whiteList = ['amazonCallBack']
const routes = [{
	path: '/',
	redirect: '/login',
}, {
	path: '/login',
	name: 'Login',
	// beforeEnter(from, to, next) {
	// 	if (!Cookies.get('token')) {

	// 		if (whiteList.indexOf(to.name) !== -1) {
	// 			// 在免登录白名单中，直接进入
	// 			next('/login')
	// 			return;
	// 		}
	// 	}
	// 	next();
	// },
	component: resolve => require(['@/views/login/login'], resolve),
	meta: {
		keepAlive: true
	}
	// component: () => import('')

}, {
	path: '/loadPage',
	name: 'loadPage',
	component: () => import('../views/loadPage.vue'),
	meta: {
		keepAlive: true
	}
},
	// 独立站登录
	{
		path: '/IndependentStationSso',
		name: 'IndependentStationSso',
		component: () => import('../views/login/IndependentStationSso.vue'),
		meta: {
			keepAlive: true
		}
	},
//收银台
{
	path: '/AccessCode',
	name: 'AccessCode',
	component: () => import('../views/Cashier/AccessCode.vue')
},
{
	path: '/InternationalCard',
	name: 'InternationalCard',
	component: () => import('../views/Cashier/InternationalCard.vue')
},
{
	path: '/Lockinexchange',
	name: 'Lockinexchange',
	component: () => import('../views/Cashier/Lockinexchange.vue')
},
{
	path: '/confirmBidPrice/:path',
	name: 'confirmBidPrice',
	component: () => import('../views/Cashier/confirmBidPrice.vue')
},
// 交易计算工具
{
	path: '/TransactionCalc',
	name: 'TransactionCalc',
	component: () => import('../views/Utils/TransactionCalc.vue')
},
{
	path: '/index', //链接路径
	beforeEnter(from, to, next) {
		// return
		// next();
		// let href = 'http://192.168.1.143:8080/relationshipList?ticket=8FdYFNXQzJq5fdAd9WMStBoG4GI0FLJM1K6Ggo9ESaZsyzQnrbjkNc%2BEdDkyjy9MuiSBzoqWfOZc%0A8P9PHzEcKw7wV68KGoYsMbzpqxgYvQzF2IM0%2FqkFOYN8Wuzekc%2FRe2LwDhfeUAco1rqRvr%2FqKQ%3D%3D'
		// let ticket = href;
		let ticket = window.location.href;
		// Cookies.set('token','aeec7f77-6107-497e-8a0b-f0660b764dc4', {
		// 	expires: 30
		// })
		if (ticket.split('ticket=')[1] == undefined) {
			if (!Cookies.get("token")) {
				next('/login')
				return
			}
			next();
		} else {
			Cookies.remove('token')
			sessionStorage.removeItem('data')
			sessionStorage.removeItem('children')

		}
		if (!Cookies.get("token")) {

		} else {
			next();
		}

		// if (!Cookies.get('token')) {
		// 	if (whiteList.indexOf(to.name) !== -1) {
		// 		// 在免登录白名单中，直接进入
		// 		next()
		// 	} else {
		// 		// 没有访问权限的其他页将重定向到登录页。
		// 		next('/login')
		// 	}
		// 	return;
		// }

	},
	name: 'Header', //路由名称，
	meta: {
		keepAlive: true
	},
	component: resolve => require(['@/views/h5Header'], resolve), //对应的组件模板
	children: [{
			path: '/home',
			component: resolve => require(['@/views/index'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/platformAccount',
			component: resolve => require(['@/views/account/platformAccount'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/GlobalDetails',
			component: resolve => require(['@/views/account/GlobalDetails'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/platformList',
			component: resolve => require(['@/views/account/platformList'], resolve),
			meta: {
				keepAlive: true
			}
		}, { //管理员管理
			path: '/accountSecurity',
			component: resolve => require(['@/views/administrators/accountSecurity'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/changePassword',
			component: resolve => require(['@/views/administrators/changePassword'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/AddAdmins',
			component: resolve => require(['@/views/administrators/AddAdmins'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/addRole',
			component: resolve => require(['@/views/administrators/addRole'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/adminList',
			component: resolve => require(['@/views/administrators/adminList'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/roleList',
			component: resolve => require(['@/views/administrators/roleList'], resolve),
			meta: {
				keepAlive: true
			}
		}, { //全球代运营
			path: '/myOperate',
			component: resolve => require(['@/views/operate/myOperate'], resolve),
			meta: {
				keepAlive: true
			}
		},{
			path: '/InitiateInquiry',
			component: resolve => require(['@/views/operate/InitiateInquiry'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/operateContract',
			component: resolve => require(['@/views/operate/operateContract'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/operateContractSign',
			component: resolve => require(['@/views/operate/operateContractSign'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/operateColl',
			component: resolve => require(['@/views/operate/operateColl'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/operateCollTwo',
			component: resolve => require(['@/views/operate/operateCollTwo'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/myOperateDelList',
			component: resolve => require(['@/views/operate/myOperateDelList'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/goHongKong',
			component: resolve => require(['@/views/operate/goHongKong'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/goHongKongTwo',
			component: resolve => require(['@/views/operate/goHongKongTwo'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/SupplierList',
			component: resolve => require(['@/views/operate/SupplierList'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/MiningFunds',
			component: resolve => require(['@/views/operate/MiningFunds'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/SigningContract',
			component: resolve => require(['@/views/operate/SigningContract'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/initiateDece',
			component: resolve => require(['@/views/operate/initiateDece'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/initiateDeceTwo',
			component: resolve => require(['@/views/operate/initiateDeceTwo'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/PaySupplier',
			component: resolve => require(['@/views/operate/PaySupplier'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/LocalDisbursement',
			component: resolve => require(['@/views/operate/LocalDisbursement'], resolve),
			meta: {
				keepAlive: true
			}
		},
		
		// 独立站
		{
			path: '/IndependentHome',
			component: resolve => require(['@/views/Independent/IndependentHome'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/IndependentAdd',
			component: resolve => require(['@/views/Independent/IndependentAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 汇款
		{
			path: '/RemittanceAll',
			component: resolve => require(['@/views/radeReceipts/RemittanceAll'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/accountAdd',
			component: resolve => require(['@/views/radeReceipts/accountAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 购付汇
		{
			path: '/PurchasePayment',
			component: resolve => require(['@/views/radeReceipts/PurchasePayment'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/exchangeAccountAdd',
			component: resolve => require(['@/views/radeReceipts/exchangeAccountAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/BatchAdd',
			component: resolve => require(['@/views/radeReceipts/BatchAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 结汇
		{
			path: '/exchangeAll',
			component: resolve => require(['@/views/radeReceipts/exchangeAll'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 换汇
		{
			path: '/barterExchang',
			component: resolve => require(['@/views/radeReceipts/barterExchang'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 收款
		{
			path: '/radeReceColl',
			component: resolve => require(['@/views/radeReceipts/radeReceColl'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 锁汇收款
		{
			path: '/LockIn',
			component: resolve => require(['@/views/radeReceipts/LockIn'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 分发
		{
			path: '/distributeAll',
			component: resolve => require(['@/views/radeReceipts/distributeAll'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 提款
		{
			path: '/drawing',
			component: resolve => require(['@/views/radeReceipts/drawing'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 转账
		{
			path: '/TransferAccounts',
			component: resolve => require(['@/views/radeReceipts/TransferAccounts'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 账户列表
		{
			path: '/accountList',
			component: resolve => require(['@/views/radeReceipts/accountList'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// 付款账户信息-查看-编辑-新增
		{
			path: '/paymentAccountOperate',
			component: resolve => require(['@/views/radeReceipts/accountListC/paymentAccountOperate'], resolve),
			meta: {
				keepAlive: true
			}
		}, // 快捷工具
		{
			path: '/shortcutTools',
			component: resolve => require(['@/views/tools/shortcutTools'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/BidPriceList',
			component: resolve => require(['@/views/login/BidPriceList'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/trade-app',
			component: resolve => require(['@/views/trade-app'], resolve),
			meta: {
				keepAlive: true
			}
		}, { //帮助中心
			path: '/helpDel',
			component: resolve => require(['@/views/help/helpDel'], resolve),
			meta: {
				keepAlive: true
			}
		}, { //充值
			path: '/rechargeAll',
			component: resolve => require(['@/views/radeReceipts/rechargeAll'], resolve),
			meta: {
				keepAlive: true
			}
		}, { //选择服务方式
			path: '/fastEntry',
			component: resolve => require(['@/views/operate/fastEntry'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{ //汇率小工具
			path: '/exchangeRateTool',
			component: resolve => require(['@/views/tools/exchangeRateTool'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{ //公私key
			path: '/pubKey',
			component: resolve => require(['@/views/publicKey/pubKey'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{ //我的牌价管理
			path: '/priceManage',
			component: resolve => require(['@/views/AccountReceivable/priceManage'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{ //锁汇异常订单
			path: '/lockingAbnormalList',
			component: resolve => require(['@/views/AccountReceivable/lockingAbnormalList'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{ //违约订单
			path: '/DefaultOrders',
			component: resolve => require(['@/views/AccountReceivable/DefaultOrders'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{ //自账户收款管理
			path: '/CollectionAccount',
			component: resolve => require(['@/views/AccountReceivable/CollectionAccount'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/CollectionAccountAdd',
			component: resolve => require(['@/views/AccountReceivable/CollectionAccountAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// {
		// 	path: '/CollectionRecordS',
		// 	component: resolve => require(['@/views/AccountReceivable/CollectionRecord'], resolve),
		// 	meta: {
		// 		keepAlive: true
		// 	}
		// },
		{
			path: '/contractor',
			component: resolve => require(['@/views/AccountReceivable/contractor'], resolve),
			meta: {
				keepAlive: true
			}
		}, {
			path: '/merchantTransfer',
			component: resolve => require(['@/views/AccountReceivable/merchantTransfer'], resolve),
			meta: {
				keepAlive: true
			}
		},{
			path: '/PickupList',
			component: resolve => require(['@/views/AccountReceivable/PickupList'], resolve),
			meta: {
				keepAlive: true
			}
		},
		// {
		// 	path: '/ChargingRecord',
		// 	component: resolve => require(['@/views/AccountReceivable/ChargingRecord'], resolve),
		// 	meta: {
		// 		keepAlive: true
		// 	}
		// }
		{ //经销商
			path: '/ResellerAccountList',
			component: resolve => require(['@/views/Reseller/ResellerAccountList'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/ResellerAccountAdd',
			component: resolve => require(['@/views/Reseller/ResellerAccountAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/ResellerList',
			component: resolve => require(['@/views/Reseller/ResellerList'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/ResellerAdd',
			component: resolve => require(['@/views/Reseller/ResellerAdd'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/DealerPaymentOrder',
			component: resolve => require(['@/views/Reseller/DealerPaymentOrder'], resolve),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/DealerPaymentOrderMatch',
			component: resolve => require(['@/views/Reseller/DealerPaymentOrderMatch'], resolve),
			meta: {
				keepAlive: true
			}
		},
		//商户入网
		{
			path: '/InternetState',
			component: resolve => require(['@/views/GoInternet/InternetState'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/enterprise',
			component: resolve => require(['@/views/GoInternet/enterprise'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/LegalPerson',
			component: resolve => require(['@/views/GoInternet/LegalPerson'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/shareholder',
			component: resolve => require(['@/views/GoInternet/shareholder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/business',
			component: resolve => require(['@/views/GoInternet/business2'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/CorporateInformation',
			component: resolve => require(['@/views/GoInternet/CorporateInformation'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		// 代理商
		{
			path: '/MerchantList',
			component: resolve => require(['@/views/MerchantManagement/MerchantList'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/AgencyOrder',
			component: resolve => require(['@/views/agent/TradeAgencyOrder'], resolve)	
		},
		{
			path: '/TradeOrder',
			component: resolve => require(['@/views/agent/TradeAgencyOrder'], resolve)
		},
		{
			path: '/TopupOrder',
			component: resolve => require(['@/views/agent/TopupOrder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/WithdrawalOrder',
			component: resolve => require(['@/views/agent/WithdrawalOrder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/RemittanceOrder',
			component: resolve => require(['@/views/agent/RemittanceOrder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/ExchangeSettlementOrder',
			component: resolve => require(['@/views/agent/ExchangeSettlementOrder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/ExchangeOrder',
			component: resolve => require(['@/views/agent/ExchangeOrder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/TransferOrder',
			component: resolve => require(['@/views/agent/TransferOrder'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/AgentHomePage',
			component: resolve => require(['@/views/agent/AgentHomePage'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/DividendDetails',
			component: resolve => require(['@/views/DividendManagement/DividendDetails'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		// 授信服务
		{
			path: '/Credit',
			component: resolve => require(['@/views/Credit/Credit'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/QuotaDetails',
			component: resolve => require(['@/views/Credit/QuotaDetails'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/Disbursements',
			component: resolve => require(['@/views/Credit/Disbursements'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/repayment',
			component: resolve => require(['@/views/Credit/repayment'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		// 本地公司服务
		{
			path: '/LocalServices',
			component: resolve => require(['@/views/LocalServices/LocalServices'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		// 收单管理
		{
			path: '/AcquirService',
			component: resolve => require(['@/views/Acquiring/AcquiringService'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/StatementCollection',
			component: resolve => require(['@/views/Acquiring/StatementCollection'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		//机构商户后台页面
		{
			path: '/SubMerchantQuery',
			component: resolve => require(['@/views/InstitutionalMerchantBackend/SubMerchantQuery'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/VAQuery',
			component: resolve => require(['@/views/InstitutionalMerchantBackend/VAQuery'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		// 本地收款
		// {
		// 	path: '/localPayment',
		// 	component: resolve => require(['@/views/radeReceipts/localPayment'], resolve),
		// 	meta: {
		// 		keepAlive: true
		// 	}	
		// },
		// 营销活动
		{
			path: '/EventDetails',
			component: resolve => require(['@/views/Mark/EventDetails'], resolve),
			meta: {
				keepAlive: true
			}	
		},
		{
			path: '/Preferential',
			component: resolve => require(['@/views/Mark/Preferential'], resolve),
			meta: {
				keepAlive: true
			}	
		}
		
	]
}]



const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});
console.log(VueRouter.prototype)
router.beforeEach((to,from,next)=>{
 	if(to.fullPath=="/login" && from.fullPath.length>2){
		next({
			path: '/login',
			query: { redirect: from.fullPath },
		})
	}
	next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
export default router;
