// var OSS = require('ali-oss');
import OSS from "ali-oss";
export function client(){
	// ||window.location.href.indexOf('http://192.168.1.136:1070') != -1 智云购修改上线加上
	if (window.location.href.indexOf('zhuim.1yunhui.com') != -1 ||window.location.href.indexOf('tszhm.1yunhui.com') != -1||window.location.href.indexOf('kilinpay.1yunhui.com') != -1||window.location.href.indexOf('zhiyungou.com') != -1||window.location.href.indexOf('1yunhui.com') != -1||window.location.href.indexOf('zhuim.1yunhui.cn') != -1||window.location.href.indexOf('120.53.212.239') != -1||window.location.href.indexOf('beijiguang') != -1) {
		console.log('线上')
		var client = new OSS({
		    endpoint: 'oss-cn-beijing.aliyuncs.com',//填写Bucket所在地域
		    accessKeyId: 'LTAI4FeWBqBbWrSaoKLX2t4Y',
		    accessKeySecret: 'PWcO8QcFftAog5Zh8LsGiSx8bDj5XX',
		    bucket: '1yunhui-bj',// 填写Bucket名称。
		})  //后端提供数据 
	} else {
		console.log('测试')
		var client = new OSS({
		    endpoint: 'oss-cn-beijing.aliyuncs.com',//填写Bucket所在地域
		    accessKeyId: 'LTAI4FuxVGz7JCfeh4oLZYUf',
		    accessKeySecret: 'egqdA3S1Ty10ZlYmQ2RANRpGKFG94l',
		    bucket: '1yunhui-qa',// 填写Bucket名称。
		})  //后端提供数据 
	}
  
  return client
}
/**
 * 生成随机uuid
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}

