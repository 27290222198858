<template>
  <div class="pageWhild" style="height: 100%;">
    <div style="margin: 20px 0px;">
      <div v-for="(item,index) in Arr" :key="index">
        <div class="module-bg">
          <div class="inline-block">
            <label  class="w100 textRight"><span class="red">*</span>{{getzhengjianText(item.type)}}：</label>
            <span>{{item.name}}</span>
          </div>
        </div>
        <!-- 股东证件类型 -->
        <div class="module-bg">
          <div class="inline-block">
            <label class="w100 textRight"><span
                class="red">*</span>{{$t('LegalPerson.Documenttype')}}：</label>
            <el-radio-group v-model="item.certificateTypeEnum" @change="TypeChange($event,index)">
              <el-radio label="ID">{{$t('LegalPerson.MainlandIDcard')}}
              </el-radio>
              <el-radio label="PASSPORT">{{$t('LegalPerson.passport')}}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- 身份证图片 -->
        <div class="module-bg" v-show="item.certificateTypeEnum=='ID'">
          <div class="inline-block" style="align-items: flex-start;">
            <label class="w100 textRight"><span class="red">*</span>{{$t('CorporateInformation.IDPhoto')}}：</label>
            <div class="Fiw500">
              <!-- 身份证人像面 -->
              <el-upload :headers="utils.headup()" class="elupload" style="margin-right: 20px;"
                         action="/file/upload" ref="upload" :on-error="handleError" accept=".jpeg,.jpg,.png"
                         multiple:limit="1" :http-request="(file)=>{return uploadURL(file,'personalIdFront',index)}">
                <!-- :on-success="(res) => { return handleAvatarSuccess(res,'file',index)}" -->
                <div class="AddUpDiv cursorcss">
                  <i class="iconfont icon-tianjia3" v-show="!item.file"></i>
                  <p v-show="!item.file">{{$t('LegalPerson.ClicktoaddIDcardface')}}</p>
                  <p class="AddUpDiv-pRight" @click.stop="Viewexample('zm')">
                    {{$t('enterprise.Viewexample')}}
                  </p>
                  <p class="AddUpDiv-pBottom">{{$t('enterprise.SupportsJPGPNGandJPGEformatsupto10MB')}}
                  </p>
                  <div v-show="item.file" class="imgFileCss">
                    <img style="width: 100%;" :src="dataFile[index].personalIdFrontFile">
                  </div>
                </div>
                <div>
                  <el-progress :percentage="dataFile[index].personalIdFrontvideoUploadPercent"></el-progress>
                </div>
              </el-upload>
              <!-- 身份证国徽面 -->
              <el-upload :headers="utils.headup()" class="elupload" action="/file/upload" ref="upload"
                         :on-error="handleError"  accept=".jpeg,.jpg,.png"
                         multiple:limit="1" :http-request="(file)=>{return uploadURL(file,'personalIdBack',index)}">
                <div class="AddUpDiv cursorcss">
                  <i class="iconfont icon-tianjia3" v-show="!item.fileBack"></i>
                  <p v-show="!item.fileBack">
                    {{$t('LegalPerson.ClicktoaddIDcardandnationalemblem')}}
                  </p>
                  <p class="AddUpDiv-pRight" @click.stop="Viewexample('fm')">
                    {{$t('enterprise.Viewexample')}}
                  </p>
                  <p class="AddUpDiv-pBottom">{{$t('enterprise.SupportsJPGPNGandJPGEformatsupto10MB')}}
                  </p>
                  <div v-show="item.fileBack" class="imgFileCss">
                    <img style="width: 100%;" :src="dataFile[index].personalIdBackFile">
                  </div>
                </div>
                <div>
                  <el-progress :percentage="dataFile[index].personalIdBackvideoUploadPercent"></el-progress>
                </div>
              </el-upload>

            </div>
          </div>
        </div>
        <!-- 护照首页 -->
        <div class="module-bg" v-show="item.certificateTypeEnum!='ID'">
          <div class="inline-block" style="align-items: flex-start;">
            <label class="w100 textRight"><span
                class="red">*</span>{{$t('CorporateInformation.IDPhoto')}}：</label>
            <div class="Fiw500">
              <el-upload :headers="utils.headup()" class="elupload" style="margin-right: 20px;"
                         action="/file/upload" ref="upload" :on-error="handleError"
                         accept=".jpeg,.jpg,.png"
                         multiple:limit="1" :http-request="(file)=>{return uploadURL(file,'passportFile',index)}">
                <div class="AddUpDiv cursorcss">
                  <i class="iconfont icon-tianjia3" v-show="!item.passportFile"></i>
                  <p v-show="!item.passportFile">{{$t('shareholder.ClicktoaddPassportHome')}}</p>
                  <p class="AddUpDiv-pRight" @click.stop="Viewexample('PassportHome')">
                    {{$t('enterprise.Viewexample')}}
                  </p>
                  <p class="AddUpDiv-pBottom">{{$t('enterprise.SupportsJPGPNGandJPGEformatsupto10MB')}}
                  </p>
                  <div v-show="item.passportFile" class="imgFileCss">
                    <img style="width: 100%;" :src="dataFile[index].passportFileFile">
                  </div>
                </div>
                <div>
                  <el-progress :percentage="dataFile[index].passportFilevideoUploadPercent"></el-progress>
                </div>
                <div style="color: red">
                  <p>{{$t('EventDetails.Pleasecarefullycheck')}}</p>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <!-- 证件号码 -->
        <div class="module-bg">
          <div class="inline-block">
            <label class="w100 textRight"><span
                class="red">*</span>{{$t('distributeAll.IdentificationNumber')}}：</label>
            <el-input v-clear-emoij class="inline-right" v-model="item.number" style="width: 450px"
                      :placeholder="$t('InternationalCard.Pleaseenter')+$t('distributeAll.IdentificationNumber')">
            </el-input>
          </div>
        </div>
        <!-- 证件有效期 -->
        <div class="module-bg">
          <div class="inline-block">
            <label class="w100 textRight"><span
                class="red">*</span>{{$t('LegalPerson.Certificatevalidityperiod')}}：</label>
            <div>
              <el-date-picker
                  style="width: 180px"
                  v-model="item.startDate"
                  type="date"
                  :picker-options='pickerStartOptions(item.endDate)'
                  value-format="yyyy-MM-dd"
                  :placeholder="$t('LegalPerson.ValidityperiodofIDcardstart')">
              </el-date-picker>
              -
              <el-date-picker
                  style="width: 180px"
                  v-model="item.endDate"
                  :disabled="item.longDate=='1'"
                  type="date"
                  :picker-options='pickerEndOptions()'
                  value-format="yyyy-MM-dd"
                  :placeholder="$t('LegalPerson.ValidityperiodofIDcardfinish')">
              </el-date-picker>
              <el-checkbox style="margin-left: 5px;" :checked="item.longDate=='1'? true:false" :true-label="1"
                           :false-label="0" v-model="item.longDate">{{$t('LegalPerson.overalongperiodoftime')}}</el-checkbox>
            </div>
          </div>
        </div>
        <div style="height: 1px;background-color: #F1F3F5;margin: 20px 0;" v-show="index!=Arr.length-1"></div>
      </div>
    </div>
    <el-dialog :title="$t('enterprise.Example')" :visible.sync="ViewexampleShow" width="400px"  append-to-body>
      <div style="margin-bottom: 10px;">
        <div style="text-align: center;height: 300px;overflow-y: scroll;" class="example">
          <img style="width: 100%;" v-for="(item,index) in ViewexampleImg" :key="index" :src="item">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {eventBus} from "@/common/eventBus";
export default {
  props:['dataLegal','ChangeData'],
  data() {
    return {
      sharholder:[],
      sltimes: [], //测试
      Arr: [],
      ViewexampleShow: false,
      ViewexampleImg: [],
      MerchantInfo: {},
      dataFile: [{
        'personalIdFrontFile': '',
        'personalIdBackFile': '',
        'passportFileFile': '',
        'shareholderAddressFileFile': '',
        'personalIdFrontvideoUploadPercent':0,
        'personalIdBackvideoUploadPercent':0,
        'passportFilevideoUploadPercent':0,
        'shareholderAddressFilevideoUploadPercent':0
      }],
      pickerStartOptions(endTime) {
        return {
          disabledDate(time) {
            if (endTime) {
              // 拼接 00:00:00 是为了统一日期从0点开始
              return time.getTime() > new Date(endTime).getTime();
            }
          }
        }
      },
      pickerEndOptions() {
        return {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7; // 禁用今天以前的日期
          }
        }
      }
    }
  },
  async created() {

    eventBus.$on('custom-shareh', () => {
      // 处理接收到的事件
      this.Lstep()
    });

    // this.MerchantInfo = await this.$common.merchantGetMerchantInfo()

    if (this.dataLegal) {
      this.sharholder = this.dataLegal.filter(item => item.type == 'SHAREHOLDER');
      this.typeMap = {
        SHAREHOLDER: this.$t('shareholder.ShareholderInformation')
      };
	  let obj={
		  longDate:'',
		  fileBack:'',
		  file:'',
		  passportFile:'',
		  startDate:'',
		  endDate:''
	  }
	  for(let i of this.sharholder){
	  		i={...i,...obj}
			this.Arr.push(i)
	  }
      // this.Arr.map((s)=>(s.file="",s.fileBack="",s.longDate="",s.passportFile="",s.startDate=""))
      this.dataFile = []
      for (let i in this.Arr) {
        let obj = {
          'personalIdFrontFile': '',
          'personalIdBackFile': '',
          'passportFileFile': '',
          'shareholderAddressFileFile': '',
          'personalIdFrontvideoUploadPercent':0,
          'personalIdBackvideoUploadPercent':0,
          'passportFilevideoUploadPercent':0,
          'shareholderAddressFilevideoUploadPercent':0
        }
        if (this.utils.IsitEmpty(this.Arr[i].file)) {
          obj.personalIdFrontFile = await this.$common.clientOSSDownload(this.Arr[i].file)
          obj.personalIdFrontvideoUploadPercent = 100
        }
        if (this.utils.IsitEmpty(this.Arr[i].fileBack)) {
          obj.personalIdBackFile = await this.$common.clientOSSDownload(this.Arr[i].fileBack)
          obj.personalIdBackvideoUploadPercent = 100
        }
        if (this.utils.IsitEmpty(this.Arr[i].passportFile)) {
          obj.passportFileFile = await this.$common.clientOSSDownload(this.Arr[i].passportFile)
          obj.passportFilevideoUploadPercent = 100
        }
        if(this.utils.IsitEmpty(this.Arr[i].longDate)){
          this.Arr[i].longDate=parseInt(this.Arr[i].longDate)
        }
        if (this.utils.IsitEmpty(this.Arr[i].shareholderAddressFile)) {
          let fileFormat = this.Arr[i].shareholderAddressFile.substring(this.Arr[i].shareholderAddressFile.lastIndexOf(".")).toLowerCase();
          if (!fileFormat.match(/.jpeg|.jpg|.png/)) {
            obj.shareholderAddressFileFile = require('../../assets/Internet/tsfile.jpg')
          }else{
            obj.shareholderAddressFileFile = await this.$common.clientOSSDownload(this.Arr[i].shareholderAddressFile)
          }
          obj.shareholderAddressFilevideoUploadPercent = 100
        }
        this.dataFile.push(obj)
      }
    }

  },
  // 通过计算属性改变字体颜色
  computed: {
    getzhengjianText() {
      return function(index) {
        return this.typeMap[index]
      };
    }
  },
  methods: {
    // AddDeleteClick(index,itemindex) {
    //   if (index == 'Add') {
    //     if (this.Arr.length == 4) {
    //       this.$message.warning(this.$t("shareholder.Shareholderscanaddnomorethan"))
    //       return false
    //     }
    //     let obj = {
    //       "identificationNumber": "",
    //       "certificateTypeEnum": "",
    //       "passportFile": "",
    //       "fileBack": "",
    //       "file": "",
    //       "shareholderAddressFile": "",
    //       "startDate": '',
    //       'endDate':'',
    //       'longDate':''
    //     }
    //     let fileobj = {
    //       'personalIdFrontFile': '',
    //       'personalIdBackFile': '',
    //       'passportFileFile': '',
    //       'shareholderAddressFileFile': '',
    //       'personalIdFrontvideoUploadPercent':0,
    //       'personalIdBackvideoUploadPercent':0,
    //       'passportFilevideoUploadPercent':0,
    //       'shareholderAddressFilevideoUploadPercent':0
    //     }
    //
    //     this.Arr.push(obj)
    //     this.dataFile.push(fileobj)
    //   } else {
    //     if (this.Arr.length == 1) {
    //       this.$message.warning(this.$t("shareholder.Shareholdersnotlessthan"))
    //       return false
    //     }
    //     this.Arr=this.Arr.splice(itemindex,1)
    //     this.dataFile=this.dataFile.splice(itemindex,1)
    //   }
    // },
    // 下一步
    async Lstep() {
      this.$emit('custom-Arr', this.Arr);


    },
    TypeChange(event, index) {
      if (event == 'ID') {
        this.Arr[index].passportFile = ''
        this.dataFile[index].passportFilevideoUploadPercent = 0
      } else {
        this.Arr[index].file = ''
        this.dataFile[index].personalIdFrontvideoUploadPercent = 0
        this.Arr[index].fileBack = ''
        this.dataFile[index].personalIdBackvideoUploadPercent = 0
      }
      this.Arr[index].number=''
      this.Arr[index].startDate=''
      this.Arr[index].endDate=''
      this.Arr[index].longDate=''
    },
    // 查看实例
    Viewexample(index) {
      this.ViewexampleImg = []
      if (index == 'zm') {
        this.ViewexampleImg.push(require('../../assets/Inter/' + index + '.jpg'))
      } else if (index == 'fm') {
        this.ViewexampleImg.push(require('../../assets/Inter/' + index + '.jpg'))
      } else if (index == 'PassportHome') {
        this.ViewexampleImg.push(require('../../assets/Inter/' + index + '.png'))
      } else if (index=='Companyoperationproofaddress'){
        this.ViewexampleImg.push(require('../../assets/Inter/' + index + 'Zh.jpg'))
        this.ViewexampleImg.push(require('../../assets/Inter/' + index + 'hongkong.jpg'))
        this.ViewexampleImg.push(require('../../assets/Inter/' + index + 'Shili.jpg'))
      }

      this.ViewexampleShow = true
    },
    // 上传文件失败
    handleError(res, file) {
      this.$refs.upload.clearFiles()
      let resm = JSON.parse(res.message)
      this.$message.warning(resm.message)
      if (resm.code == '401') {
        this.$router.push('/login')
      }
      this.openLoading().close();
    },
    beforeUploadVideo(file) {
      if (file.size > 20 * 1024 * 1024) {
        this.$message.warning(this.$t("login.Uploadfileistoolarge"))
        return false   //必须返回false
      }
      this.openLoading();
    },
    // 直传oss
    async uploadURL(file, type, index) {
      let filePath = file.file.name
      let fileFormat = filePath.substring(filePath.lastIndexOf(".")).toLowerCase();
       if (file.file.size / 1024 / 1024 > 20) {
        this.$message.warning(this.$t('enterprise.Pleaseuploadafilenotexceeding20minsize') )
        await this.openLoading().close();
        return false
      }
      if(type == 'file'||type == 'fileBack'||type == 'passportFile'){
        if (!fileFormat.match(/.jpeg|.jpg|.png/)) {
          this.$message.warning(this.$t('radeReceColl.Incorrectfileformat') )
          await this.openLoading().close();
          return false
        }
      } else if(type == 'shareholderAddressFile'){
        if (!fileFormat.match(/.jpeg|.jpg|.png|.pdf/)) {
          this.$message.warning(this.$t('radeReceColl.Incorrectfileformat') )
          await this.openLoading().close();
          return false
        }
      } else{
        if (!fileFormat.match(/.jpeg|.jpg|.png|.pdf|.rar|.zip/)) {
          this.$message.warning(this.$t('radeReceColl.Incorrectfileformat') )
          await this.openLoading().close();
          return false
        }
      }

      if (type == 'file') {
        this.dataFile[index].personalIdFrontvideoUploadPercent=0
      } else if (type == 'fileBack') {
        this.dataFile[index].personalIdBackvideoUploadPercent=0
      } else if (type == 'passportFile') {
        this.dataFile[index].passportFilevideoUploadPercent=0
      } else if (type == 'shareholderAddressFile') {
        this.dataFile[index].shareholderAddressFilevideoUploadPercent=0
      }

      let ObjOss=await this.$common.clientOSS(file,this,'ShareHolderRouter',this.dataFile[index],type)

      let OrcDataObj=''

      if (type == 'personalIdFront') {
        this.Arr[index].file = ObjOss.oldFile
        this.dataFile[index].personalIdFrontFile = await this.$common.clientOSSDownload(ObjOss.oldFile)

        OrcDataObj=await this.$common.OrcRecognize('front','ID',ObjOss.oldFile)
        if(this.utils.IsitEmpty(OrcDataObj)&&this.utils.IsitEmpty(OrcDataObj.idInfo)){
          // this.Arr[index].personName=OrcDataObj.idInfo.name
          this.Arr[index].number=OrcDataObj.idInfo.idNo
        }else{
          this.Arr[index].number=''
        }
      } else if (type == 'personalIdBack') {
        this.Arr[index].fileBack = ObjOss.oldFile
        this.dataFile[index].personalIdBackFile = await this.$common.clientOSSDownload(ObjOss.oldFile)

        OrcDataObj=await this.$common.OrcRecognize('back','ID',ObjOss.oldFile)
        if(this.utils.IsitEmpty(OrcDataObj)&&this.utils.IsitEmpty(OrcDataObj.idInfo)){
          if(OrcDataObj.idInfo.signDate){
            this.Arr[index].startDate= moment(OrcDataObj.idInfo.signDate).format("YYYY-MM-DD")
          }else{
            this.Arr[index].startDate=''
          }
          if(!OrcDataObj.idInfo.expireDate.indexOf('长期')){
            this.Arr[index].longDate=1
          }else{
            this.Arr[index].endDate= moment(OrcDataObj.idInfo.expireDate).format("YYYY-MM-DD")
          }
        }else{

          this.Arr[index].startDate=''
          this.Arr[index].endDate=''
          this.Arr[index].longDate=''
        }
      } else if (type == 'passportFile') {
        this.Arr[index].passportFile = ObjOss.oldFile
        this.dataFile[index].passportFileFile = await this.$common.clientOSSDownload(ObjOss.oldFile)

        OrcDataObj=await this.$common.OrcRecognize('','PASSPORT',ObjOss.oldFile)
        if(this.utils.IsitEmpty(OrcDataObj)&&this.utils.IsitEmpty(OrcDataObj.passportInfo)){
          // this.Arr[index].personName=OrcDataObj.passportInfo.name
          this.Arr[index].number=OrcDataObj.passportInfo.passportNo
          if(OrcDataObj.passportInfo.signDate){
            this.Arr[index].startDate=moment(OrcDataObj.passportInfo.signDate).format("YYYY-MM-DD")
          }else{
            this.Arr[index].startDate=''
          }
          if(!OrcDataObj.passportInfo.validDate.indexOf('长期')){
            this.Arr[index].longDate=1
          }else{
            this.Arr[index].endDate=moment(OrcDataObj.passportInfo.validDate).format("YYYY-MM-DD")
          }

        }else{
          this.Arr[index].number=''
          this.Arr[index].startDate=''
          this.Arr[index].endDate=''
          this.Arr[index].longDate=''
        }
      } else if (type == 'shareholderAddressFile') {
        this.Arr[index].shareholderAddressFile = ObjOss.oldFile
        let fileFormat = this.Arr[index].shareholderAddressFile.substring(this.Arr[index].shareholderAddressFile.lastIndexOf(".")).toLowerCase();
        if (!fileFormat.match(/.jpeg|.jpg|.png/)) {
          this.dataFile[index].shareholderAddressFileFile = require('../../assets/Internet/tsfile.jpg')
        }else{
          this.dataFile[index].shareholderAddressFileFile = await this.$common.clientOSSDownload(ObjOss.oldFile)
        }
      }

      await this.openLoading().close();
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .inline-block {
  align-items: baseline;
}
/deep/ .elupload{
  width: 100%;
}
.inputZj {
  width: 435px;
}
.AddUpDiv {
  width: 100%;
  // background: #FAFCFF;
  border: 1px solid #D7DDE7;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #B7C0CF;
  text-align: center;
  padding-bottom: 60px;
  position: relative;

  &-pRight {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1B62F0;
    padding: 6px 14px;
  }

  &-pBottom {
    position: absolute;
    bottom: 0;
    height: 35px;
    background: #FFF4F2;
    border-radius: 3px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #EF6B58;
    line-height: 35px;
    width: 100%;
  }
  &>i {
    color: #D7DDE7;
    font-size: 42px;
    margin-top: 40px;
    margin-bottom: 20px;
    display: inline-block;
  }
}

.H3p {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FC373C;
  margin-left: 20px;
}

/deep/ .module-bg {
  margin: 0 10px;
}

.AddCss {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #1B62F0;
}
</style>
