import Vue from 'vue'
import axios from 'axios';
import Cookies from 'js-cookie'
// import router from "../router/index.js"
import router from'../router'
import versionTood from '../common/versionUpdate.js'
import common from '../common/common.js'

import {
	Message
} from "element-ui";
import { Loading } from 'element-ui';
let loading;
let needLoadingRequestCount = 0; //当前正在请求的数量
function showLoading() {
   if (needLoadingRequestCount === 0 && !loading) {
      loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
      });
   }
   needLoadingRequestCount++;
}
function closeLoading() {
   Vue.nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      needLoadingRequestCount--;
      needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 保证大于等于0
      if (needLoadingRequestCount === 0) {
         if (loading) {
            loading.close();
            loading = null;
         }
      }
   });
}
// export const BASE_URL = '/api';
// export const BASE_URL = '';
// export const BASE_URL = 'http://192.168.1.101:8080';
// export const BASE_URL = 'http://192.168.1.98:8080';
// export const BASE_URL = 'http://192.168.1.142:8080';
// export const BASE_URL = 'http://192.168.1.57:8080'; 
let BASE=''
if (window.location.href.indexOf('localhost') != -1) {
	BASE = '';
} else{
	BASE = '/api';
}

let AfricanTimestampObj={
	'OnTime':'',
	'EndTime':''
}

const http = axios.create({
	baseURL: BASE,
	// timeout: 5000
})
export const BASE_URL=BASE

let pending = []; // 创建一个数组保存所有取消请求的cancel方法和请求url
const CancelToken = axios.CancelToken; // 获取axios的CancelToken
// 清除请求方法
const removePending = (config) => {
  for (const p in pending) {
 	 // 判断url是否重复，重复择取消请求，
 	 // /api/system/dict/getDictByType为过滤需要取消的rul  && ['/api/system/dict/getDictByType'].includes(config.url)
    if (pending[p].url === config.url && repeatUrllast().includes(config.url)) { 
      pending[p].fun(); // 执行取消
    }
  }
  pending = pending.filter(item=>item.url !== config.url)// 过滤掉已经返回的请求
}
/**
 *request拦截器
 */
http.interceptors.request.use((config) => {
	// todo headers 添加token
	 
	 showLoading()// 开启加载
	 versionTood.isNewVersion()
	
	config.headers.type = '1' //固定
	config.headers.satoken = localStorage.getItem('satoken')
	config.headers.cashierToken = sessionStorage.getItem('cashierToken')
	
	if (Cookies.get("token") !== undefined) {
		config.headers.token = Cookies.get("token") //把localStorage的token放在Authorization里
		
	}
	if(JSON.parse(sessionStorage.getItem('dataLogin'))&&JSON.parse(sessionStorage.getItem('dataLogin')).userInfo&&JSON.parse(sessionStorage.getItem('dataLogin')).userInfo.merchantNo){
		config.headers.merchantNo = JSON.parse(sessionStorage.getItem('dataLogin')).userInfo.merchantNo
	}else{
		config.headers.merchantNo = ''
	}
	if(localStorage.getItem('lang')!== null){
		if(localStorage.getItem('lang')=='cn'){
			config.headers.language = 'zh'
		}else{
			config.headers.language = 'en'
		}
	}else{
		config.headers.language = 'zh'
	}
	config.headers.client = 'client'

	if (config.method === "get") {
		config.data = {
			unused: 0
		}; // 这个是关键点，加入这行就可以了,解决get,请求添加不上Content-Type
	}
	config.headers["Content-Type"] = "application/json"
	// if (Cookies.get("token") !== undefined) {
	// 	config.headers.authorization = Cookies.get("token"); //把localStorage的token放在Authorization里
	// }
	AfricanTimestampFun(config)
	// 在响应拦截中调用方法
	removePending(config)
	// 所有请求添加到数组中
		config.cancelToken = new CancelToken(executor => {
	      pending.push({ url: config.url, fun: executor });
	    })
	return config;
	
}, (err) => {
	Promise.reject(err)

})

// 接口增加参数
function requestMethod(config) {
	let i = '';
	
	switch (config.method) {
		case 'get':
		    if(config.url.indexOf('?')!=-1){
				config.url+='&Mathid='+Math.round(Math.random()*999999999)+'&timestamp='+new Date().getTime()
			}else{
				config.url+='?&Mathid='+Math.round(Math.random()*999999999)+'&timestamp='+new Date().getTime()
			} 
			break;
		case 'post':
			i = config.data
			break;
	}
	// 在请求拿到的参数中统一加上某一些参数（根据自己的项目需求追加）
	// 根据项目需要也可以统一做其他操作
	i = Object.assign(i, {
		Mathid: Math.round(Math.random()*999999999),
		timestamp: new Date().getTime()
	})
}

// 非洲网络 特定接口增加时间戳
function AfricanTimestampFun(config) {
	for(let i of interceptUrl()){
		if(config.url.indexOf(i)!=-1){
			AfricanTimestampObj.OnTime=new Date().getTime()
			break
		}
	}
}

// 需要拦截的接口
function interceptUrl() {
	let url=[
		'/receive/list',
		'/remittance/remittanceList',
		'/fxSettle/fxSettlementRecordList',
		'/fxSettle/fxSettlementRemitRecordList',
		'/exchange/list',
		'/globalAgentOperation/fileList',
		'/receive/preTxp',
		'/receive/createTxp',
		'/remittance/remittancePreTxp',
		'/remittance/remittanceTxp',
		'/fxSettle/preCheck',
		'/fxSettle/commitTxp',
		'/exchange/createTxp',
		'/lockOrder/lockPreTxp',
		'/lockOrder/pageLockReceiveOrder'
	]
	return url
}

// 重复的接口只使用最后一次
function repeatUrllast() {
	let url=[
		'/receive/calcuReceiveOrderAmount',
	]
	return url
}
// const autoLogin = function () {
//   http.get('/login', {
//     params: {
//       loginName: 'visitor',
//       password: md5('visitor@2020')
//     }

//   }).then(res => {

//   })
// }

/**
 * response拦截器
 */
http.interceptors.response.use(
	(response) => {
		
		// console.log(response, "response")
		const {
			code,
			message
		} = response.data;
		let message403='您没有此权限，请联系管理员!'
		closeLoading()// 关闭加载
		if(localStorage.getItem('lang')!== null){
			if(localStorage.getItem('lang')!='cn'){
				message403 = 'You do not have this permission, please contact the administrator!'
			}
		}
		
		if(response.data.code=='0000'){
			for(let i of interceptUrl()){
				if(response.config.url.indexOf(i)!=-1){
					// AfricanTimestampObj.EndTime=new Date().getTime()
					common.BuriedPointInterface(AfricanTimestampObj.OnTime,response.config.url)
					// http.get('/merchant/timing',{
					// 	params:{},
					// 	headers : {
					// 	    'X-Request-Id': encodeURIComponent(response.config.url),
					// 		'X-Timing':AfricanTimestampObj.EndTime - AfricanTimestampObj.OnTime
					// 	}
					// })
					// .then((res) => {
						
					// })
					break
				}
			}
		}
		if (code == '401') {
			Message({	
				message: message,
				type: 'warning',
				customClass:'messageRED'
			});
			// router.push('/login')
		}
		return response;
	}, (err) => {
		closeLoading()// 关闭加载
		if (err.response.data.code == '401') {
			Message({
				message: err.response.data.message,
				type: 'warning',
				customClass:'messageRED'
			});
			router.push('/login')
		}
		Message({
			message: err.response.data.message,
			type: 'warning',
			customClass:'messageRED'
		});
		return Promise.reject(err)
	}
)

export default http
