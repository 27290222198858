import Vue from 'vue';
import axios from 'axios'
import router from'../router'
import Cookies from 'js-cookie'
const vm = new Vue();
const isNewVersion = () => {
	let url = `//${ window.location.host }/version.json?r=`+new Date().getTime();
	axios.get(url).then(res => {
		let version = res.data.version;
		let localVersion = localStorage.getItem('version');
		if (localVersion && localVersion != version) {
			if (confirm("系统检测到有新版本，是否更新？")) {
				localStorage.setItem('version', res.data.version);
				Cookies.remove('token')
				router.push('/')
				window.location.reload();
				return;
			}
		} else {
			localStorage.setItem('version', res.data.version);
		}
	});
};
export default {
	isNewVersion
}
