<template>
  <!--  证件更新提示-->
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :lock-scroll="false"
      :show-close="showCancel"
      width="669px"
      :destroy-on-close="true"
      :before-close="handleClose"
      class="dia-example"
  >
    <div class="confirm-dialog-content example dia-example-div">
      <!-- 企业法人/董事信息 -->
      <Corporate  v-if="types.includes('LEGAL')" :dataLegal="content" @child-corpor="copor"></Corporate>
<!--      股东的-->
      <div  v-if="types.includes('SHAREHOLDER')" >
        <ShareholderDocuments :dataLegal="content" @custom-Arr="ArrData"></ShareholderDocuments>
        <div style="height: 1px;background-color: #F1F3F5;margin: 20px 0;" ></div>
      </div>
      <div  v-if="types.some(value => value === 'BUSINESS_LICENSE' || value === 'REGISTER_CI' || value === 'REGISTER' || value === 'TAX_CERTIFICATE' )" >
<!--        企业注册CI 税务证书或商业登记等  公司注册证明 企业营业执照-->
        <Otherdocuments :Otherdos="content" @custom-other="otherData"></Otherdocuments>
      </div>
      <div class="confirm-dialog-buttons">
        <el-button type="primary" size="small"  @click="Submit">{{ confirmButtonText }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ShareholderDocuments from "@/components/expirationCom/ShareholderDocuments.vue";
import {eventBus} from "@/common/eventBus";
import Corporate from "@/components/expirationCom/Corporate.vue";
import Otherdocuments from "@/components/expirationCom/Otherdocuments.vue";
import moment from "moment/moment";

export default {
  name:'DocumentChange',
  components: {Otherdocuments, Corporate,ShareholderDocuments,},
  props: {
    title: {
      type: String,
      default: 'Confirmation'
    },
    dialogVisible: {
      type: Boolean,
      default : false
    },
    showCancel: {
      type: Boolean,
      default : true
    },
    content: {
      type: Array,
      default: function () {
        return []
      }
    },
    handleClose: {
      type: Function,
      default: () => {}
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
  },
  data() {
    return {
      types:[],
      coporData:'',
      customArrData:'',
      otherArrData:''
    };
  },
  methods: {
    // 获取股东信息
    ArrData(data){
      this.customArrData=data
    },
    otherData(data){
      this.otherArrData=data
    },
    // 法人信息
    copor(data){
      this.coporData=data
    },
    async Submit() {
      eventBus.$emit('custom-corpor')
      eventBus.$emit('custom-otherdoc')
      eventBus.$emit('custom-shareh')
      // 法人
      if(this.coporData!='') {
        for (let i of this.coporData) {
          // 证件类型
          if (!i.certificateTypeEnum) {
            this.$message({
              message: this.$t("select.pleasechoose") + this.$t("LegalPerson.Documenttype") + '！',
              type: 'warning'
            });
            return false
          }
          // 证件照片
          if (i.certificateTypeEnum == 'ID') {
            if (!i.file) {
              this.$message({
                message: this.$t("Otherinformation.Pleaseselectthefaceimageofthelegalrepresentativewitness") + '！',
                type: 'warning'
              });
              return false
            }
            if (!i.fileBack) {
              this.$message({
                message: this.$t("Otherinformation.PleaseselectthenationalemblemonthelegalrepresentativeIDcard") + '！',
                type: 'warning'
              });
              return false
            }
          } else {
            if (!i.passportFile) {
              this.$message({
                message: this.$t("Otherinformation.Pleaseselectthehomepageofthelegalrepresentativepassport") + '！',
                type: 'warning'
              });
              return false
            }
          }
          // 证件号码
          if (!i.number) {
            this.$message({
              message: this.$t("Otherinformation.PleaseenterthelegalrepresentativeIDnumber") + '！',
              type: 'warning'
            });
            return false
          }
          // 证件有效期
          if (!i.startDate) {
            this.$message({
              message: this.$t("Otherinformation.Pleaseselectthestarttimeforthelegalrepresentativeidentificationdocument")  + '！',
              type: 'warning'
            });
            return false
          }
          i.startDate = moment(i.startDate).format("YYYY-MM-DD")
          // 是否长期
          if (i.longDate != '1') {
            if (!i.endDate) {
              this.$message({
                message: this.$t("Otherinformation.PleaseselectPleaseselecttheendtimeofthelegalrepresentativecertificate")  + '！',
                type: 'warning'
              });
              return false
            }
           i.endDate = moment(i.endDate).format("YYYY-MM-DD")
          } else {
            i.endDate = ''
          }
        }
      }
      // 股东
      if(this.customArrData!=''){
        for (let i of this.customArrData) {
          // 证件类型
          if (!i.certificateTypeEnum) {
            this.$message({
              message: this.$t("select.pleasechoose") + this.$t("LegalPerson.Documenttype") + '！',
              type: 'warning'
            });
            return false
          }
          // 证件照片
          if (i.certificateTypeEnum == 'ID') {
            if (!i.file) {
              this.$message({
                message: this.$t("Otherinformation.Pleaseselectthefaceimageofthelegalrepresentativewitness2") + '！',
                type: 'warning'
              });
              return false
            }
            if (!i.fileBack) {
              this.$message({
                message: this.$t("Otherinformation.Pleaseselectthenationalemblemonthelegalrepresentative2")  + '！',
                type: 'warning'
              });
              return false
            }
          } else {
            if (!i.passportFile) {
              this.$message({
                message: this.$t("Otherinformation.Pleaseselectthehomepageofthelegalrepresentative2") + '！',
                type: 'warning'
              });
              return false
            }
          }
          // 证件号码
          if (!i.number) {
            this.$message({
              message: this.$t("Otherinformation.PleaseenterthelegalrepresentativeIDnumber2") + '！',
              type: 'warning'
            });
            return false
          }
          // 证件有效期
          if (!i.startDate) {
            this.$message({
              message: this.$t("Otherinformation.Pleaseselectthestarttimeforthelegalrepresentativeidentificationdocument2") + '！',
              type: 'warning'
            });
            return false
          }
          i.startDate = moment(i.startDate).format("YYYY-MM-DD")
          // 是否长期
          if (i.longDate != '1') {
            if (!i.endDate) {
              this.$message({
                message: this.$t("Otherinformation.PleaseselectPleaseselecttheendtimeofthelegalrepresentativecertificate2") + '！',
                type: 'warning'
              });
              return false
            }
            i.endDate = moment(i.endDate).format("YYYY-MM-DD")
          } else {
            i.endDate = ''
          }
        }
      }
      // 其他证件
      if(this.otherArrData!=''){
        for (let i of this.otherArrData) {
          // 证件照片
          if (!i.file) {
            this.$message({
              message: this.$t('Otherinformation.Pleaseuploadthefile') + '！',
              type: 'warning'
            });
            return false
          }
          // 证件有效期
          if (!i.startDate) {
            this.$message({
              message: this.$t('expire.Pleaseselectthestarttimeofthedocument') + '！',
              type: 'warning'
            });
            return false
          }
          i.startDate=moment(i.startDate).format("YYYY-MM-DD")
          // 是否长期
          if(i.longDate!='1'){
            console.log(i)
            if (!i.endDate) {
              this.$message({
                message: this.$t('expire.Pleaseselecttheendtimeoftheocument') + '！',
                type: 'warning'
              });
              return false
            }
            i.endDate=moment(i.endDate).format("YYYY-MM-DD")
          }else {
            i.endDate = ''
          }
        }
      }
      // console.log('12',[...this.coporData,...this.otherArrData,...this.customArrData])
      const DataAll = [...this.coporData,...this.otherArrData,...this.customArrData]
      const res = await this.$http.post('/main/submitCertificateDate', DataAll)
      if (res.data.code == "0000") {
        this.$message.success(this.$t('myOperateDelList.Submittedsuccessfully'))
        this.$emit('confirm');
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning'
        });
      }
    },
  },
  mounted() {
    //后端返的有过期/未过期数组进行过滤
    this.types = this.content.map(item => item.type);
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__error {
  display: none;
}
/deep/ .elupload {
  width: 95%;
}

/deep/ .el-upload {
  width: 95%;
}
.confirm-dialog-buttons {
  text-align: center;
  margin-top: 20px;
}
</style>
