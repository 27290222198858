<template>
<!--  证件到期提示-->
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :lock-scroll="false"
      :show-close="showCancel"
      width="450px"
      :destroy-on-close="true"
      :before-close="handleClose"
      :close-on-press-escape="false"
  >
    <div class="confirm-dialog-content">
      <div v-if="showCancel">
        <img src="@/assets/Expiringsoon.png">
        <p>{{$t('expire.your')}}【{{ chineseTypes.join(', ') }}】{{$t('expire.Thecertificateisabouttoexpire')}} </p>
      </div>
      <div v-else>
        <img src="@/assets/Expireddocuments.png">
        <p>{{$t('expire.your')}}【{{ chineseTypes.join(', ') }}】{{$t('expire.TheIDhasexpired')}}</p>
      </div>
      <div class="confirm-dialog-buttons">
        <el-button type="primary" @click="confirm" size="small">{{ confirmButtonText }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name:'ExpirationReminder',
  props: {
    title: {
      type: String,
      default: 'Confirmation'
    },
    dialogVisible: {
      type: Boolean,
      default : false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    content: {
      type: Array,
      default: function () {
        return []
      }
    },
    handleClose: {
      type: Function,
      default: () => {}
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
  },
  data() {
    return {
      data:{},
      chineseTypes:[],
    };
  },
  created() {
  },
  mounted() {
      const typeMap = {
        BUSINESS_LICENSE: this.$t('enterprise.Enterprisebusinesslicense'),
        REGISTER_CI: this.$t('enterprise.EnterpriseRegistrationCl'),
        REGISTER: this.$t('enterprise.companyregistrationcertificate'),
        TAX_CERTIFICATE: this.$t('enterprise.Taxcertificateorbusinessregistration'),
        LEGAL: this.$t('LegalPerson.Legalperson'),
        SHAREHOLDER: this.$t('shareholder.ShareholderInformation')
      };
      let arr = this.content.map(item => typeMap[item.type]);
       this.chineseTypes = Array.from(new Set(arr));
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  }
};
</script>

<style lang="less" scoped>
 p {
   text-align: center;
 }
.confirm-dialog-content {
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.confirm-dialog-buttons {
  text-align: center;
  margin-top: 20px;
}
</style>
