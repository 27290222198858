<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :lock-scroll="false"
      width="400px"
      :destroy-on-close="true"
      @close="handleClose"
      :modal="false"

  >
    <div class="confirm-dialog-content">
      <p>{{ content }}</p>
      <div class="confirm-dialog-buttons">
        <el-button type="primary" @click="confirm" size="small">{{ confirmButtonText }}</el-button>
        <el-button @click="cancel" v-if="showCancel" size="small">{{ cancelButtonText }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  props: {
    title: {
      type: String,
      default: 'Confirmation'
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$emit('close');
      });
    },
    confirm() {
      this.$emit('confirm');
      this.dialogVisible = false;
    },
    cancel() {
      this.$emit('cancel');
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
.confirm-dialog-content {
  padding: 20px;
}

.confirm-dialog-buttons {
  text-align: center;
  margin-top: 20px;
}
</style>
