import Vue from 'vue';
import ConfirmDialog from './ConfirmDialog.vue';

// 创建一个 Vue 组件构造函数
const ConfirmDialogConstructor = Vue.extend(ConfirmDialog);

let instance; // 存储实例

function showConfirmDialog(options) {
    options = options || {};

    // 创建一个新的实例，如果实例已经存在，则直接更新参数
    if (!instance) {
        instance = new ConfirmDialogConstructor({
            el: document.createElement('div')
        });
    }

    // 设置组件的参数
    Object.keys(options).forEach(key=>{
        instance[key] = options[key]|| ''
    })
    instance.dialogVisible = true; // 显示对话框


    // 将组件添加到文档中
    document.body.appendChild(instance.$el);

    // 返回一个 Promise，以便处理用户的确认或取消操作
    return new Promise((resolve, reject) => {
        // 设置确认和取消的回调函数
        instance.confirm = () => {
            resolve();
            closeConfirmDialog();
        };

        instance.cancel = () => {
            reject();
            closeConfirmDialog();
        };
    });
}

// 关闭确认框
function closeConfirmDialog() {
    console.log(instance)
    if (instance) {
        document.body.removeChild(instance.$el);
        instance = null;
    }
}

// 定义插件
const ConfirmDialogPlugin = {
    install(Vue) {
        Vue.prototype.$confirm = showConfirmDialog;
    }
};

export default ConfirmDialogPlugin;
