import Vue from 'vue'
import http from "../api/http.js"
import {
	getUUID
} from "../utils/index.js"
import {
	client,
	getFileNameUUID
} from '../utils/alioss.js'
import {
	Message
} from "element-ui";
import i18n from './i18n'
// import { map } from 'core-js/library/fn/dict';

// 方形国家图标
const iconCountryF = (value) => {
	if (value == 'AED') {
		return 'icon-AED'
	} else if (value == 'CANGO') {
		return 'icon-CANGO'
	} else if (value == 'ZAR') {
		return 'icon-ZAR'
	} else if (value == 'JPY') {
		return 'icon-JPY'
	} else if (value == 'AUD') {
		return 'icon-AUD'
	} else if (value == 'RWF') {
		return 'icon-RWF'
	} else if (value == 'IDR') {
		return 'icon-IDR'
	} else if (value == 'GHS') {
		return 'icon-GHS'
	} else if (value == 'VND') {
		return 'icon-VND'
	} else if (value == 'SGD') {
		return 'icon-SGD'
	} else if (value == 'RUB') {
		return 'icon-RUB'
	} else if (value == 'CNY' || value == 'CNH') {
		return 'icon-CNH'
	} else if (value == 'RUB') {
		return 'icon-RUB'
	} else if (value == 'HKD') {
		return 'icon-HKD'
	} else if (value == 'PHP') {
		return 'icon-PHP'
	} else if (value == 'NGN') {
		return 'icon-NGN'
	} else if (value == 'TWD') {
		return 'TWD'
	} else if (value == 'KRW') {
		return 'icon-KRW'
	} else if (value == 'SEK') {
		return 'icon-SEK'
	} else if (value == 'MYR') {
		return 'icon-MYR'
	} else if (value == 'EUR') {
		return 'icon-EUR'
	} else if (value == 'KES') {
		return 'icon-KES'
	} else if (value == 'NZD') {
		return 'icon-NZD'
	} else if (value == 'TZS') {
		return 'icon-TZS'
	} else if (value == 'UGX') {
		return 'icon-UGX'
	} else if (value == 'USD') {
		return 'icon-USD'
	} else if (value == 'SAR') {
		return 'icon-SAR'
	} else if (value == 'CHF') {
		return 'icon-CHF'
	} else if (value == 'CAD') {
		return 'icon-CAD'
	} else if (value == 'GBP') {
		return 'icon-GBP'
	} else if (value == 'CDF') {
		return 'icon-CDF'
	} else if (value == 'THB') {
		return 'icon-THB'
	} else if (value == 'INR') {
		return 'icon-INR'
	} else if (value == 'ARS') {
		return 'icon-ARS'
	} else if (value == 'ZMW') {
		return 'icon-ZMW'
	} else if (value == 'BRL') {
		return 'icon-BRL'
	} else if (value == 'CLP') {
		return 'icon-CLP'
	} else if (value == 'COP') {
		return 'icon-COP'
	} else if (value == 'MXN') {
		return 'MXN'
	} else if (value == 'UYU') {
		return 'icon-UYU'
	} else if (value == 'PKR') {
		return 'icon-PKR'
	} else if(value=='ZAR'){
		return 'icon-ZAR'
	}else if(value=='ETB'){
		return 'icon-ETB'
	}else if(value=='EGP'){
		return 'icon-EGP'
	}else if(value=='XOF_BJ'){
		return 'icon-XOF_BJ'
	}else if(value=='XOF_CI'){
		return 'icon-XOF_CI'
	} else if (value == 'TRY') {
		return 'icon-TRY'
	} else if (value == 'XAF_CM') {
		return 'icon-XAF_CM'
	} else if (value == 'MAD' || value == 'MAR') {
		return 'icon-MAR'
	} else if (value == 'XOF_BF') {
		return 'icon-XOF_BF'
	} else if (value == 'LAK') {
		return 'icon-LAK'
	}
}
// 圆形国家图标
const iconCountry = (value) => {
	if (value == 'EUR') {
		return 'icon-EUR-Y'
	} else if (value == 'CAD') {
		return 'icon-CAD-Y'
	} else if (value == 'GHS') {
		return 'icon-GHS-Y'
	} else if (value == 'NGN') {
		return 'icon-NGN-Y'
	} else if (value == 'KRW') {
		return 'icon-KRW-Y'
	} else if (value == 'AUD') {
		return 'icon-AUD-Y'
	} else if (value == 'TZS') {
		return 'icon-TZS-Y'
	} else if (value == 'RUB') {
		return 'icon-RUB-Y'
	} else if (value == 'SEK') {
		return 'icon-SEK-Y'
	} else if (value == 'GBP') {
		return 'icon-GBP-Y'
	} else if (value == 'SGD') {
		return 'icon-SGD-Y'
	} else if (value == 'NZD') {
		return 'icon-NZD-Y'
	} else if (value == 'HDK') {
		return 'icon-HDK-Y'
	} else if (value == 'TWD') {
		return 'icon-TWD-Y'
	} else if (value == 'PHP') {
		return 'icon-PHP-Y'
	} else if (value == 'SAR') {
		return 'icon-SAR-Y'
	} else if (value == 'USD') {
		return 'icon-USD-Y'
	} else if (value == 'UGX') {
		return 'icon-UGX-Y'
	} else if (value == 'IDR') {
		return 'icon-IDR-Y'
	} else if (value == 'CHF') {
		return 'icon-CHF-Y'
	} else if (value == 'CANGO') {
		return 'icon-CANGO-Y'
	} else if (value == 'JPY') {
		return 'icon-JPY-Y'
	} else if (value == 'MYR') {
		return 'icon-MYR-Y'
	} else if (value == 'AED') {
		return 'icon-AED-Y'
	} else if (value == 'VND') {
		return 'icon-VND-Y'
	} else if (value == 'CNY' || value == 'CNH') {
		return 'CNF'
	} else if (value == 'CHF') {
		return 'icon-CHF-Y'
	} else if (value == 'RWF') {
		return 'icon-RWF-Y'
	} else if (value == 'KES') {
		return 'icon-KES-Y'
	} else if (value == 'HKD') {
		return 'icon-HDK-Y'
	} else if (value == 'THB') {
		return 'icon-THB-Y'
	} else if (value == 'INR') {
		return 'icon-INR-Y'
	} else if (value == 'ARS') {
		return 'icon-ARS-Y'
	} else if (value == 'ZMW') {
		return 'icon-ZMW-Y'
	} else if (value == 'BRL') {
		return 'icon-BRL-Y'
	} else if (value == 'CLP') {
		return 'icon-CLP-Y'
	} else if (value == 'COP') {
		return 'icon-COP-Y'
	} else if (value == 'MXN') {
		return 'MXN'
	} else if (value == 'UYU') {
		return 'icon-UYU-Y'
	} else if (value == 'PKR') {
		return 'icon-PKR-Y'
	}else if(value=='ZAR'){
		return 'icon-ZAR-Y'
	}else if(value=='ETB'){
		return 'icon-ETB-Y'
	}else if(value=='EGP'){
		return 'icon-EGP-Y'
	}else if(value=='XOF_BJ'){
		return 'icon-XOF_BJ-Y'
	}else if(value=='XOF_CI'){
		return 'icon-XOF_CI-Y'
	} else if (value == 'TRY') {
		return 'icon-TRY-Y'
	} else if (value == 'XAF_CM') {
		return 'icon-XAF_CM-Y'
	}else if (value == 'MAD' || value == 'MAR') {
		return 'icon-MAR-Y'
	} else if (value == 'XOF_BF') {
		return 'icon-XOF_BF-Y'
	} else if (value == 'LAK') {
		return 'icon-LAK-Y'
	}

}
// 按国家州/省获取全部城市
const getCitesWithState = (countryCode, stateCode) => {
	return http.get('/worldCountryCity/getCitesWithState?countryCode=' + countryCode + '&stateCode=' + stateCode)
}

// 国家
const allCountries = () => {
	return http.get('/worldCountryCity/allCountries')
}

// 按国家获取全部州省
const getAllStateWithCountryCode = (countryCode) => {
	return http.get('/worldCountryCity/getAllStateWithCountryCode?countryCode=' + countryCode)
}

// 按国家获取全部城市
const getCitesWithCountryCode = (countryCode) => {
	return http.get('/worldCountryCity/getCitesWithCountryCode?countryCode=' + countryCode)
}

// 收银台国家,省,市
// 按国家州/省获取全部城市
const CashiergetCitesWithState = (countryCode, stateCode) => {
	return http.get('/cashier/getCitesWithState?countryCode=' + countryCode + '&stateCode=' + stateCode)
}

// 国家
const CashierallCountries = () => {
	return http.get('/cashier/getCountries')
}

// 按国家获取全部州省
const CashiergetAllStateWithCountryCode = (countryCode) => {
	return http.get('/cashier/getStateWithCountryCode?countryCode=' + countryCode)
}

// 查询币种账户
//accountCurrencyType 查询的账户类型 PLANT-平台类型 AGENT-代运营类型
//isNeedBalance 是否需要余额的显示，不需要false 需要true 默认false
const accountlist = async (accountCurrencyType, isNeedBalance) => {
	let country = ''
	await http.get('/account/list?accountCurrencyType=' + accountCurrencyType + '&isNeedBalance=' +
		isNeedBalance).then(res => {
		if (res.data.code == '0000') {
			for (let i of res.data.data) {
				i.iconF = iconCountryF(i.currency)
				i.icon = iconCountry(i.currency)
			}
			country = res.data.data

		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return country
}

// 全部币种
const CurrencyList = async () => {
	let country = ''
	await http.get('/account/getCurrencyList').then(res => {
		if (res.data.code == '0000') {
			let Currency = res.data.data
			let objCountry = {
				value: Object.keys(Currency),
				name: Object.values(Currency)
			}
			country = objCountry

		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return country
}

// 查询特殊币种账户
//accountCurrencyType 查询的账户类型 PLANT-平台类型 AGENT-代运营类型
//isNeedBalance 是否需要余额的显示，不需要false 需要true 默认false
const receiveCurrencyList = async (accountCurrencyType, isNeedBalance) => {
	let country = ''
	let HttpUrl = 'account/receiveCurrencyList'
	await http.get(HttpUrl+'?accountCurrencyType=' + accountCurrencyType +
		'&isNeedBalance=' +
		isNeedBalance).then(res => {
		if (res.data.code == '0000') {
			for (let i of res.data.data) {
				i.iconF = iconCountryF(i.currency)
				i.icon = iconCountry(i.currency)
			}
			country = res.data.data

		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return country
}

// 获取锁汇付款币种列表
//accountCurrencyType 查询的账户类型 PLANT-平台类型 AGENT-代运营类型
//isNeedBalance 是否需要余额的显示，不需要false 需要true 默认false
//sellCurrency  收款币种
const lockPaymentCurrencyList = async (accountCurrencyType, isNeedBalance ,receiveCurrency) => {
	let country = ''
	await http.get('/lockOrder/getLockBuyCurrencyList?accountCurrencyType=' + accountCurrencyType +
		'&isNeedBalance=' + isNeedBalance+'&sellCurrency='+receiveCurrency).then(res => {
		if (res.data.code == '0000') {
			for (let i of res.data.data) {
				i.iconF = iconCountryF(i.currency)
				i.icon = iconCountry(i.currency)
			}
			country = res.data.data

		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return country
}

// 交易牌价接口
const getRealTimeBidPrice = async (outCurrency, inCurrency,index,path) => {
	let country = ''
	if(index){
		if(index=='AGENTHongKong'){
			await http.get('/bidPrice/getRealTimeBidPrice?outCurrency=' + outCurrency + '&inCurrency=' +
				inCurrency+'&currencyType=AGENT').then(res => {
				if (res.data.code == '0000') {
					country = res.data.data
				} else {
					if(path!='InitiateInquiry'){
						Message({
							message: res.data.message,
							type: 'warning'
						});
					}
				}
			})
		}else{
			await http.get('/bidPrice/getRealTimeBidPrice4AgentFxSettlement?outCurrency=' + outCurrency + '&inCurrency=' +
				inCurrency+'&agentMerchantNo='+index ).then(res => {
				if (res.data.code == '0000') {
					country = res.data.data
				} else {
					Message({
						message: res.data.message,
						type: 'warning'
					});
				}
			})
		}
	}else{
		await http.get('/bidPrice/getRealTimeBidPrice?outCurrency=' + outCurrency + '&inCurrency=' +
			inCurrency).then(res => {
			if (res.data.code == '0000') {
				country = res.data.data
			} else {
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})
	}

	return country
}

// 锁汇交易牌价接口
const getLockTimeBidPrice = async (outCurrency, inCurrency,agentType="PLANT") => {
	let country = ''
		await http.get('/lockOrder/getLockTimeBidPrice?buyCurrency=' + outCurrency + '&sellCurrency=' +
			inCurrency+'&agentType='+agentType).then(res => {
			if (res.data.code == '0000') {
				country = res.data.data
			} else {
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})


	return country
}

// 代运营交易牌价接口（锁汇与非锁汇统一）换汇出款lockExchange 传0，  锁汇出款 lockExchange 传1
const BidPriceGetLockTimeBidPrice = async (inCurrency, outCurrency,currencyType,lockExchange) => {
	let country = ''
		await http.get('/bidPrice/getRealTimeBidPrice?outCurrency=' + outCurrency + '&inCurrency=' +
			inCurrency+'&currencyType='+currencyType+'&lockExchange='+lockExchange).then(res => {
			if (res.data.code == '0000') {
				country = res.data.data
			} else {
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})


	return country
}

// 短信邮件接口
// templateNo:00002 汇款手机验证码 00003 汇款邮箱验证码通知 00004 修改绑定手机验证码
// captchaType:REST_PHONE 重置手机号 TRANSACTION_VERIFICATION 交易验证 REST_PASSWORD 重置密码
const messagesend = async (templateNo, captchaType, code, uuid) => {
	let country = ''
	if (window.location.href.indexOf('kilinpay.1yunhui.com') != -1) {
		if (templateNo == '00002') {
			templateNo = '00013'
		} else if (templateNo == '00003') {
			templateNo = '00012'
		} else if (templateNo == '00004') {
			templateNo = '00015'
		}
	}
	let Obj = {
		"templateNo": templateNo,
		"notifyType": "VERIFICATION",
		"captchaType": captchaType,
		"uuid": uuid,
		"code": code
	}

	await http.post('/message/send', Obj).then(res => {
		if (res.data.code == '0000') {
			country = 'true'
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})

	let obj = {
		uuid: uuid,
		country: country
	}
	return obj
}

const messagesendWithoutCode = async (templateNo, captchaType, receive,v2LoginData) => {
	let uuid = getUUID()
	let country = ''
	let SlideWhy=false
	let Obj = {
		"templateNo": templateNo,
		"notifyType": "VERIFICATION",
		"captchaType": captchaType,
		"uuid": uuid,
		"receive": receive
	}
	if(templateNo=='00007'){//代理商登录页面忘记密码之前调用接口
		if(window.location.href.indexOf('http://192.168.1.136:96/') != -1||window.location.href.indexOf('zhuim-agent.1yunhui.com') != -1||window.location.href.indexOf('http://192.168.1.136:1071') != -1||window.location.href.indexOf('m-agent.zhiyungou.com') != -1||sessionStorage.getItem("platformType")=='AGENT'){
			Obj.appType='2'
		}
		v2LoginData.form=Obj
		Obj=v2LoginData
	}
	await http.post('/message/v2.0/sendMsgWithOutAuth', Obj).then(res => {
		if (res.data.code == '0000') {
			country = 'true'
		} else {
			SlideWhy=true
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	let obj = {
		uuid: uuid,
		country: country,
		SlideWhy:SlideWhy
	}
	return obj
}

// 支付地址发送到邮箱
const messagesendWithoutCodeMail = async (receive, payUrl, payUrlPw) => {
	let uuid = getUUID()
	let country = ''
	// let context = {
	// 	"payUrl": payUrl,
	// 	"payUrlPw": payUrlPw
	// }
	let Obj = {
		// "templateNo": '00008',
		// "notifyType": "NOTICE",
		// "uuid": uuid,
		// "receive": receive,
		// "context": context
		orderNo:payUrl,
		receive:receive
	}
	await http.post('/receive/sendPayUrl', Obj).then(res => {
		if (res.data.code == '0000') {
			country = 'true'
			Message({
				message: i18n.tc('myOperateDelList.Emailsentsuccessfully') + '！',
				type: 'success'
			});
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	let obj = {
		uuid: uuid,
		country: country
	}
	return obj
}

// 是否能做业务
// businessType:REMITRECEIPT 贸易收款、代运营收款     TRANSFER  换汇（有独特的查询业务开关接口 csrfToken）   CROSSBORDERREMITTANCE 汇款，代运营转款   PAYMENTS 分发      GLOBALACCOUNT  我的账户（va账户）   PAYMENTS 极速结汇/到账   FXSETTLEMENT   结汇    AGENT_PAYMENT：代采下单 AGENT_FXSETTLEMENT：代采付款  WITHDRAWL：提款  DISBURSE_MERCHANT 代运营本地出款 DISBURSE_AGENT  服务商出款订单
const checkBusinessStatus = async (index,typeNo) => {
	let Obj = {
		type: false,
		googleKeyStatus: '',
		FAST_ARRIVAL: '',
		FAST_FX_SETTLE: '',
		lockInStatus:'',
		employee:'',
		msg: '',
		HOLIDAY_FX_SETTLE:'',
		csrfToken: ''
	}
	if(index=='AGENT_PAYMENT'||index=='AGENT_FXSETTLEMENT'){
		await http.get('/business/checkBusinessStatus?businessType=' + index +'&accountNo='+ typeNo).then(res => {
			if (res.data.code == '0000') {
				Obj.type = true
				Obj.googleKeyStatus = res.data.data.googleKeyStatus
				Obj.FAST_ARRIVAL = res.data.data.FAST_ARRIVAL
				Obj.FAST_FX_SETTLE = res.data.data.FAST_FX_SETTLE
				Obj.lockInStatus = res.data.data.lockInStatus
				Obj.employee = res.data.data.employee
				Obj.HOLIDAY_FX_SETTLE=res.data.data.HOLIDAY_FX_SETTLE
				Obj.csrfToken=res.data.data.csrfToken
				Obj={...Obj,...res.data.data}
			} else if (res.data.code == '9998') {
				Obj.type = false
				Obj.msg = res.data.message
			} else if (res.data.code == '9001') {
				Obj.type = false
			} else {
				Obj.type = false
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})
	}else{
		await http.get('/business/checkBusinessStatus?businessType=' + index).then(res => {
			if (res.data.code == '0000') {
				Obj.type = true
				Obj.googleKeyStatus = res.data.data.googleKeyStatus
				Obj.FAST_ARRIVAL = res.data.data.FAST_ARRIVAL
				Obj.FAST_FX_SETTLE = res.data.data.FAST_FX_SETTLE
				Obj.lockInStatus = res.data.data.lockInStatus
				Obj.employee = res.data.data.employee
				Obj.HOLIDAY_FX_SETTLE=res.data.data.HOLIDAY_FX_SETTLE
				Obj.csrfToken=res.data.data.csrfToken
				Obj.fxSettle2PlatformAccountOpen=res.data.data.fxSettle2PlatformAccountOpen
				Obj={...Obj,...res.data.data}
			} else if (res.data.code == '9998') {
				Obj.type = false
				Obj.msg = res.data.message
			} else if (res.data.code == '9001') {
				Obj.type = false
			} else {
				Obj.type = false
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})
	}

	return Obj
}

// 金额
const Amount = (Amount) => {
	Amount = Amount.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
	Amount = Amount.replace(/^\./g, ''); //必须保证第一个为数字而不是.
	Amount = Amount.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
	if(Amount.indexOf('.')!=-1){
		Amount = Amount.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
		Amount = Amount.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
		if (Amount.indexOf(".") < 0 && Amount != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
			Amount = parseFloat(Amount);
		}
	}
	if (Amount.length > 13&&Amount.indexOf('.')==-1) {//小数点前13位数，超过删除
		Amount = Amount.substring(0, 13);
	}
	if (Amount.length > 18) {
		Amount = Amount.substring(0, 18);
	}
	return Amount
}

// 英文和数字
const REG_EN_Fun = (Amount) => {
	Amount = Amount.replace(/[^a-zA-Z0-9]/g, ""); //清除“数字”和“字母”以外的字符
	return Amount
}

//包括0的整数
const REG_ZERO_INTEGER_FUN = (Amount) => {
	Amount = Amount.replace(/[^\d]/g, ""); //清除“数字”以外的字符
	return Amount
}
// 语言只能是英文(其他语言不行)
const language_EN = (index) => {
	if(/[\u4e00-\u9fa5]/.test(index)) {
	    //替换汉字字符
	    index = index.replace(/[\u4e00-\u9fa5]/g, '');
	}
	return index
}

// 去除中文和中文符号
const RemoveChineseAndChineseSymbols = (val) => {
	const SpacesString = val.replace(/[^\x00-\xff]/g, '');
	return SpacesString;
}

// 牌价金额
const PriceAmount = (Amount) => {
	Amount = Amount.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
	Amount = Amount.replace(/^\./g, ''); //必须保证第一个为数字而不是.
	Amount = Amount.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
	Amount = Amount.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
	Amount = Amount.replace(/^(\-)*(\d{1,10})\.(\d\d\d\d\d\d).*$/, '$1$2.$3'); //只能输入两个小数
	if (Amount.indexOf(".") < 0 && Amount != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
		Amount = parseFloat(Amount);
	}
	// if (Amount.length > 10) {
	// 	Amount = Amount.substring(0, 10);
	// }
	return Amount
}
const special = (val)=>{
	// 使用正则表达式替换掉非英文字符和数字的部分
	const formattedString = val.replace( /[^A-Za-z0-9\s+\-?().,']+/g, '');
	return formattedString;
}

// 去除空格
const RemoveSpaces = (val)=>{
	// 使用正则表达式替换掉非英文字符和数字的部分
	const SpacesString = val.replace(/\s*/g, '');
	return SpacesString;
}


// 下载模板
//  AGENT_OPERATION_币种.doc：代运营国家地区协议模板
//  TRADING_DETAILS.xlsx： 贸易收款模板
// 	CARGO_DETAILS.xlsx： 汇款模板(针对物流商)
// 	SUPPLIER_DETAILS.zip： 汇款模板(针对供货商 ZIP)
// 	DISPUTE_DETAILS.xlsx： 分发模板
// FXSETTLE_DETAILS.xlsx  多人结汇模板
// UPLOAD_PAYEE.xlsx    结汇银行卡模板
// AGENT_PAYMENT_SUPPLIER_DETAILS.xlsx：代采供货商模板
// AGENT_PAYMENT_DETAILS.xlsx：代采明细模板
//EMPLOYEE_DETAIL    务工人员信息表
const specificTemplate = async (templateNo, countryCode) => {
	let Obj = {
		type: false,
		url: ''
	}
	if (templateNo == 'GLOBAL_REGION_TEMPLATE_KEY') {
		await http.get('/template/specificTemplate?templateNo=' + templateNo + '&countryCode=' + countryCode)
			.then(res => {
				if (res.data.code == '0000') {
					Obj.type = true
					Obj.url = res.data.data.url
					window.open(res.data.data.url)
				} else {
					Obj.type = false
					Message({
						message: res.data.message,
						type: 'warning'
					});
				}
			})
	} else {
		await http.get('/template/specificTemplate?templateNo=' + templateNo).then(res => {
			if (res.data.code == '0000') {
				Obj.type = true
				Obj.url = res.data.data.url
				window.open(res.data.data.url)
			} else {
				Obj.type = false
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})
	}


	return Obj
}

// 结汇下载明细信息模板    OVERSEAS_SHOP, IMPORT_E_COMMERCE, CARGO, ADVERTISING_SERVICES, OFFLINE_TRADE
const getDetailTemplateName = async (templateNo) => {
	let Obj = {
		type: false,
		url: ''
	}
	await http.get('/fxSettle/getDetailTemplateName?fundsDeclareType=' + templateNo).then(res => {
		if (res.data.code == '0000') {
			Obj.type = true
			Obj.url = res.data.data
			window.open(res.data.data)
		} else {
			Obj.type = false
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return Obj
}

const TabFun = (index) => { // 动态导航栏
	let optionTitle = sessionStorage.getItem('middle_act')
	let optionRoutes = index
	if (optionTitle == '管理员列表' || optionTitle == 'Admin List') {
		for (let i in optionRoutes) {
			optionRoutes[i].path == "/administratorsList" ? optionRoutes[i].meta.keepAlive = true : ''
			optionRoutes[i].path == "/administratorsListEdit" ? optionRoutes[i].meta.keepAlive = true : ''
		}
	}
}

const openedsFun = (index, openedsdata) => { //左侧导航栏动态展开
	let openedsData = openedsdata
	for (let i of openedsData) {
		if (i.children.length > 0) {
			for (let j of i.children) {
				if (j.title == index) {
					return i.title
				}
			}
		} else if (i.title == index) {
			return i.title
		}

	}
}

// 列表下载表单
const Downloadform = async (orderNo, orderStatus, createStartTime, createEndTime) => {
	await http.get('/remittance/remittanceDistributeDownloadList', {
		params: {
			'orderNo': orderNo,
			'orderStatus': orderStatus,
			'createStartTime': createStartTime,
			'createEndTime': createEndTime
		}
	}).then(res => {
		if (res.data.code == '0000') {
			window.open(res.data.data)
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
}
// 商户入网回显填写步骤、审核状态、 业务状态、 具体信息
const merchantGetMerchantInfo = async (orderNo, orderStatus, createStartTime, createEndTime) => {
	let obj = {}
	await http.get('/merchant/getMerchantInfo').then(res => {
		if (res.data.code == '0000') {
			obj = res.data.data

		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return obj
}

// 查询代理商下的商户编号、名称，用于查询条件
const MerchantnameListInfo = async () => {
	let obj = {}
	await http.get('/agent/getAgentMerchantNos').then(res => {
		if (res.data.code == '0000') {
			obj = res.data.data
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return obj
}


// 查询代理商支持的币种
const getAgentCurrencys = async () => {
	let obj = {}
	await http.get('/agent/getAgentCurrencys').then(res => {
		if (res.data.code == '0000') {
			obj = res.data.data
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return obj
}

// 回显文件
const downLoadProofFile = async (index) => {
	let obj = ''
	await http.get('/remittance/downLoadProofFile?proofFilePath=' + index).then(res => {
		if (res.data.code == '0000') {
			obj = res.data.data.url

		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return obj
}

// OCR证件识别
// cardSide: 人像面-front，国徽面：back
// BANK_CARD-银行卡,BUSINESS_LICENSE-营业执照,HK_MACAU_EXIT_ENTRY_PERMIT-港澳通行证,ID-身份证,PASSPORT-护照,TAIWAN_EXIT_ENTRY_PERMIT-台湾通行证
const OrcRecognize = async (cardSide, certificateType, file,customOcrTemplate) => {
	let obj={}
	if(customOcrTemplate!='true'){
		customOcrTemplate='false'
	}
	await http.post('/orc/recognize', {
		'cardSide':cardSide,
		'certificateType':certificateType,
		'file':file,
		'customOcrTemplate':customOcrTemplate
	}).then(res => {
		if (res.data.code == '0000') {
			obj=res.data.data
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return obj
}


// oss直传
const clientOSS = async (file, that ,name,type,index,merchantNo) => {
	let OSSObj = {
		oldFile: '',
		videoUploadPercent: '',
		oldFileName: ''
	}
	let menuStat={
		'userInfo':{}
	}
	let timeOn=new Date().getTime()
	if(name=='initiateDeceRouters'){
		menuStat.userInfo.merchantNo=merchantNo
		name='initiateDeceRouter'
	}else{
		if(!JSON.parse(sessionStorage.getItem('dataLogin'))){
			menuStat.userInfo.merchantNo=new URLSearchParams(window.location.search).get('merchantNo')
		}else{
			menuStat = JSON.parse(sessionStorage.getItem('dataLogin'))
		}
	}
	console.log(menuStat)
	//注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
	var fileName = 'bj/file/' + menuStat.userInfo.merchantNo + "/" + `${Date.parse(new Date())}` + '.' + file.file.name.substring(file.file.name.lastIndexOf(".")+1);
	//定义唯一的文件名，打印出来的uid其实就是时间戳
	await client().multipartUpload(fileName, file.file, {
		timeout: 3600000, // 超时时间
		progress: function(percentage, cpt) {
			if(name=='videoUploadPercentAddnewcontract'){
				that.videoUploadPercentAddnewcontract = parseInt(percentage * 100)
			}else if(name=='ShareHolderRouter'){
				if (index == 'personalIdFront') {
					type.personalIdFrontvideoUploadPercent=parseInt(percentage * 100)
				} else if (index == 'personalIdBack') {
					type.personalIdBackvideoUploadPercent=parseInt(percentage * 100)
				} else if (index == 'passportFile') {
					type.passportFilevideoUploadPercent=parseInt(percentage * 100)
				} else if (index == 'shareholderAddressFile') {
					type.shareholderAddressFilevideoUploadPercent=parseInt(percentage * 100)
				}
			}else if(name=='LegalPersonRouter'){
				that[type + 'videoUploadPercent']=parseInt(percentage * 100)
			}else if(name=='myOperateDelListRouter'){
				if (type == 'supportingfile') {
					that.supportingfilevideoUploadPercent=parseInt(percentage * 100)
				} else if (type == 'File') {
					that.FilevideoUploadPercent=parseInt(percentage * 100)
				}else if (type == 'proofFileNameoldFilePath') {
					that.proofFileNameoldFilePathvideoUploadPercent=parseInt(percentage * 100)
				} else if (type == 'rowDatafileNameoldFilePath')  {
					that.rowDatafileNameoldFilePathvideoUploadPercent=parseInt(percentage * 100)
				} else if (type == 'oldFilePath') {
					that.oldFilePathvideoUploadPercent=parseInt(percentage * 100)
				} else  if (type == 'detailFileNameoldFilePath') {
					that.detailFileNameoldFilePathvideoUploadPercent=parseInt(percentage * 100)
				} else if(type=='detailsOssPathName'){
					that.detailsOssPathNamevideoUploadPercent=parseInt(percentage * 100)
				}
			}else if(name=='initiateDeceRouter'){
				that[type + 'videoUploadPercent']=parseInt(percentage * 100)
				// if (type == 'Supplier') {
				// 	that.SuppliervideoUploadPercent=parseInt(percentage * 100)
				// } else  {
				// 	that.MiningvideoUploadPercent=parseInt(percentage * 100)
				// }
			}else{
				that.videoUploadPercent = parseInt(percentage * 100)
			}
		}
	}).then((res) => {
		//此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
		OSSObj.oldFile = fileName;
		OSSObj.oldFileName = file.file.name
	}).catch(err => {
		if(name=='videoUploadPercentAddnewcontract'){
			that.videoUploadPercentAddnewcontract = parseInt(0 * 100)
		}else if(name=='ShareHolderRouter'){
			if (index == 'personalIdFront') {
				type.personalIdFrontvideoUploadPercent=parseInt(0 * 100)
			} else if (index == 'personalIdBack') {
				type.personalIdBackvideoUploadPercent=parseInt(0 * 100)
			} else if (index == 'passportFile') {
				type.passportFilevideoUploadPercent=parseInt(0 * 100)
			} else if (index == 'shareholderAddressFile') {
				type.shareholderAddressFilevideoUploadPercent=parseInt(0 * 100)
			}
		}else if(name=='LegalPersonRouter'){
			that[type + 'videoUploadPercent']=parseInt(0 * 100)
		}else if(name=='myOperateDelListRouter'){
			if (type == 'supportingfile') {
				that.supportingfilevideoUploadPercent=parseInt(0 * 100)
			} else if (type == 'File') {
				that.FilevideoUploadPercent=parseInt(0 * 100)
			}else if (type == 'proofFileNameoldFilePath') {
				that.proofFileNameoldFilePathvideoUploadPercent=parseInt(0 * 100)
			} else if (type == 'rowDatafileNameoldFilePath')  {
				that.rowDatafileNameoldFilePathvideoUploadPercent=parseInt(0 * 100)
			} else if (type == 'oldFilePath') {
				that.oldFilePathvideoUploadPercent=parseInt(0 * 100)
			} else  if (type == 'detailFileNameoldFilePath') {
				that.detailFileNameoldFilePathvideoUploadPercent=parseInt(0 * 100)
			} else if(type=='detailsOssPathName'){
				that.detailsOssPathNamevideoUploadPercent=parseInt(0 * 100)
			}
		}else if(name=='initiateDeceRouter'){
			that[type + 'videoUploadPercent']=parseInt(0 * 100)
				// if (type == 'Supplier') {
				// 	that.SuppliervideoUploadPercent=parseInt(0 * 100)
				// } else  {
				// 	that.MiningvideoUploadPercent=parseInt(0 * 100)
				// }
		} else{
			that.videoUploadPercent = parseInt(0 * 100)
		}
		OSSObj.oldFile = '';
		OSSObj.oldFileName = ''
	})
	await BuriedPointInterface(timeOn,fileName)
	return OSSObj
}

// oss下载
const clientOSSDownload = async (name) => {
	console.log('name',name)
	let OSSFile = {
		filePath: '',
		code: '',
		msg: ''
	}
	try {
		// 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
		// 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
		const result = await client().signatureUrl(name, {
			// response: {
			// 	'content-disposition': 'attachment; filename="' + name + '.' + type + '"'
			// },
			expires: 3600
		});
		OSSFile.code = 200;
		OSSFile.msg = "SUCCESS";
		OSSFile.filePath = result;
	} catch (e) {
		OSSFile.code = 500;
		OSSFile.msg = e;
		OSSFile.filePath = "";
	} finally {
		return OSSFile.filePath;
	}
}

// 汇款原因列表接口
const payTypeList = async (index) => {
	let Arr = []
	await http.get('remittance/payTypeList',{
		params:{
			'id':index
		}
	}).then(res => {
		if (res.data.code == '0000') {
			Arr = res.data.data
		}else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return Arr
}

// 校验商户授信服务状态  授信业务类型 REPAY 还款 REMIT 出款
const checkCreditBusinessStatus = async (creditAccountId, creditBusinessType, currency) => {
	let Obj = {
		googleKeyStatus: '',
		FAST_ARRIVAL: '',
		FAST_FX_SETTLE: '',
		businessSet: '',
		msg:''
	}
	await http.post('/creditAccount/checkCreditBusinessStatus',{
			"creditAccountId": creditAccountId,
			"creditBusinessType": creditBusinessType,
			"currency": currency
	}).then(res => {
		if (res.data.code == '0000') {
			Obj = res.data.data
		}else if (res.data.code == '9998') {
			Obj.msg = res.data.message
		} else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return Obj
}

// 汇款/结汇账户/本地出款账户   获取账户主体类型和支持的币种。 通过businessType区分， 汇款：TRADE_REMIT  结汇：TRADE_REMIT_CNY   代运营本地出款：DISBURSE_MERCHANT
const getPayeeSubjectType = async (businessType) => {
    let Obj={}
	await http.get('/payee/getPayeeSubjectType',{
			params:{
				businessType: businessType,
			}
	}).then(res => {
		if (res.data.code == '0000') {
			Obj = res.data.data
		}else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return Obj
}
 // 汇款/结汇账户/本地出款账户   根据业务和币种查询支持的国家code
const getPayeeCountry = async (businessType,subjectType,currency) => {
    let Obj={}
	await http.get('/payee/getPayeeCountry',{
			params:{
				businessType: businessType,
				subjectType: subjectType,
				currency: currency
			}
	}).then(res => {
		if (res.data.code == '0000') {
			Obj = res.data.data
		}else {
			Message({
				message: res.data.message,
				type: 'warning'
			});
		}
	})
	return Obj
}
function formatAmountWithCommas(amount) {
	// Convert the amount to a string
	const amountStr = `${amount}`.toString();
	// Split the amount into integer and decimal parts (if applicable)
	const [integerPart, decimalPart] = amountStr.split('.');
	// Add thousand separators to the integer part
	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	// If there's a decimal part, add it back to the formatted integer part
	const formattedAmount = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    if(formattedAmount){
        return formattedAmount;
    }
    return ''
}

// 接口埋点
const BuriedPointInterface = async (timeOn,identifying) => {
	let dataLogin=JSON.parse(sessionStorage.getItem("dataLogin"))?JSON.parse(sessionStorage.getItem("dataLogin")):''
	let Obj = {}
	let EndTime=new Date().getTime()
	if(dataLogin.timingStatus){
		await http.get('/merchant/timing',{
			params:{},
			headers : {
			    'X-Request-Id': encodeURIComponent(identifying),
				'X-Timing':EndTime - timeOn
			}
		}).then(res => {
			if (res.data.code == '0000') {
				// Obj = res.data.data
			} else {
				Message({
					message: res.data.message,
					type: 'warning'
				});
			}
		})
	}
	return Obj
}

/**
 * bidPrice 牌价
 * baseCurrency 基准币种
 * outCurrency  卖出币种
 * inCurrency  买入币种
 * amount
 * return 计算后的金额
 * */
function calcAmount({baseCurrency,outCurrency,inCurrency,amount=0,Qutions={}}){

	if(!outCurrency || !inCurrency){
		return 0
	}
	const _baseCurrency = baseCurrency || Qutions.baseCurrency
	const bidPrice = (baseCurrency == outCurrency ? Qutions.bidPriceBuy:Qutions.bidPriceSell) || Qutions.pageShowBidPrice ||0
	// console.log(_baseCurrency,outCurrency,inCurrency,amount,bidPrice)
	if (_baseCurrency == outCurrency) {
		return Math.round((amount * bidPrice) * 100) / 100
		// (this.data.inAmount * this.bidPrice).toFixed(2)
	} else if(_baseCurrency == inCurrency) {
		return Math.round((amount / bidPrice) * 100) / 100
		// (this.data.inAmount / this.bidPrice).toFixed(2)
	}
	return 0;
}

// 区号
const getMobileAreaCodes = async () => {
	let obj = {}
	const res = await http.get('/merchantDealer/getMobileAreaCodes')
	if(res.data.code ==='0000'){
	    obj = res.data.data
	} else{
		Message.info(res.data.msg)
	}
	return obj
}

// 通过枚举类名获取枚举值
const TypeEnumeration = async (type) => {
	let obj = ''
	await http.get('/common/getEnumValueByName?enumName=' + type).then(res => {
		if (res.data.code == '0000') {
			obj = res.data.data
		} else {
			Message({
				message: res.data.msg,
				type: 'warning'
			});
		}
	})
	return obj
}

// 子商户列表接口
const SubMerListFun = async () => {
	let obj = ''
	await http.get('/server/merchant/list', {
			params: {
				"pageIndex": 1,
				"pageSize": 9999,
				'verifyStatus': 'VERIFYAGREE',
			}
		})
		.then((res) => {
			if (res.data.code == "0000") {
				res.data.data.records.map(v=>(v.label=v.companyName,v.value=v.merchantNo))
				obj = res.data.data.records
			} else {
				Message({
					message: res.data.msg,
					type: 'warning'
				});
			}
		})
	return obj
}


// 付款账户币种
const getReceiveNotNeedPayerPayeeCurrencyList = async (type) => {
	let obj = []
	const res = await http.get('/payee/getReceiveNotNeedPayerPayeeCurrencyList')
	if(res.data.code ==='0000'){
	  obj = res.data.data
	}
	return obj
}

// 获取需要添加的文件列表
const getPayeeNameAndFileRequire = async (currency,type) => {
	let obj = {}
	const res = await http
	  .get('/receive/getPayeeNameAndFileRequire', {
	    params: {
	      currency: currency,
	      sourceType: type
	    }
	  })
	if (res.data.code == "0000") {
		obj=res.data.data
	}else {
	    Message({
	    	message: res.data.message,
	    	type: 'warning'
	    });
	} 
	return obj
}


export default {
	formatAmountWithCommas,
	getCitesWithState,
	getAllStateWithCountryCode,
	getCitesWithCountryCode,
	messagesend,
	messagesendWithoutCode,
	accountlist,
	receiveCurrencyList,
	allCountries,
	TabFun,
	openedsFun,
	iconCountry,
	iconCountryF,
	getRealTimeBidPrice,
	checkBusinessStatus,
	specificTemplate,
	getDetailTemplateName,
	Amount,
	PriceAmount,
	special,
	messagesendWithoutCodeMail,
	Downloadform,
	merchantGetMerchantInfo,
	downLoadProofFile,
	clientOSS,
	checkCreditBusinessStatus,
	lockPaymentCurrencyList,
	CashiergetCitesWithState,
	CashierallCountries,
	CashiergetAllStateWithCountryCode,
	MerchantnameListInfo,
	getAgentCurrencys,
	getLockTimeBidPrice,
	REG_EN_Fun,
	REG_ZERO_INTEGER_FUN,
	OrcRecognize,
	getPayeeSubjectType,
	getPayeeCountry,
	language_EN,
	BuriedPointInterface,
	CurrencyList,
	clientOSSDownload,
	payTypeList,
	getMobileAreaCodes,
	RemoveSpaces,
	RemoveChineseAndChineseSymbols,
	TypeEnumeration,
	BidPriceGetLockTimeBidPrice,
	SubMerListFun,
	getReceiveNotNeedPayerPayeeCurrencyList,
	calcAmount,
	getPayeeNameAndFileRequire,
}
