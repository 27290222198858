import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: null || localStorage.getItem('lang'),
	catchArr: [
		'Login',
		'loadPage'
	]
  },
  mutations: {
    langmut(state,n) {
      state.lang = n
      localStorage.setItem('lang',n)
    }
  }
})
