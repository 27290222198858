<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
		<!-- <keep-alive :exclude="cached">
			<router-view :key="$route.fullPath" />
		</keep-alive> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cached: this.$store.state.catchArr,
        isRouterAlive: true,
			}
		},
    provide() {
      return {
        reload: this.reload,
      };
    },
    created() {
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
      },
    },
    watch:{
      '$i18n.locale':{
        handler(v){
         this.reload()
        }
      }
    }
	}
</script>

<style>
</style>
