<template>
	<el-container>
		<div :style="{' width: 200px': isCollapse}" style="height: 100vh;background-color: #3A54B3;z-index: 20;"
			:class="iphonecss" @click="div2()">
			<!-- <div>
				<p>风控系统</p>
			</div> -->
			<div @click.stop="div1()" :style="{width:(isCollapseequipment=='移动端'?'200px':'')}" class="scrollY">


				<el-menu :unique-opened="true" :default-active="subDef" :default-openeds='openeds'
					class="el-menu-vertical-demo" style="" :collapse="isCollapse">
					<div class="el-top">
						<img v-if="KiLinPayLogo" src="../assets/LKPindex-logo.png">
						<img v-else-if="SystemLogo.zhiyunG=='ZHIYUNGOU'" style="width: 86px;" src="../assets/zhiyungou/zyg-logo.png">
						<img v-else-if="SystemLogo.zhiyunG=='BEIJIGUANG'" style="width: 86px;" src="../assets/zhiyungou/bjg-logo.png">
						<img v-else src="../assets/index-logo.png">
					</div>
					<div class="el-top-line"></div>
					<div v-for="(item,index) in data" :key="index">
						<el-submenu v-if="item.children.length>0" :index="item.title">
							<template slot="title">
								<i class="iconfont" :class="item.icon"
									style="margin-right: 10px !important;font-size: 14px;opacity: 0.7;"></i>
								<span slot="title" v-show="!isCollapse">{{item.title}}</span></template>
							<el-menu-item @click="goRouter(ite)" v-for="(ite,ind) in item.children" :key="ind"
								:index="ite.href">{{ite.title}}
							</el-menu-item>
						</el-submenu>
						<el-menu-item v-else :index="item.href" @click="goRouter(item)">
							<i class="iconfont" :class="item.icon"
								style="margin-right: 10px !important;font-size: 14px;opacity: 0.7;"></i>
							<span slot="title">{{item.title}}</span>
						</el-menu-item>
					</div>
				</el-menu>
			</div>
		</div>
		<el-container class="el-container-css" style="height: 100vh;">
			<el-header style=" font-size: 12px;display: flex;justify-content: space-between;" class="iphonecssherd">
				<div style="display: flex;align-items: center;">
					<i style="cursor: pointer;font-size: 20px;color: #384c6d;" @click="isClick()"
						class="iconfont icon-zhedie"></i>

					<!-- <div style="margin: 0px 20px;">
							<el-breadcrumb separator-class="el-icon-arrow-right">
								<el-breadcrumb-item>首页</el-breadcrumb-item>
								<el-breadcrumb-item>活动管理</el-breadcrumb-item>
							</el-breadcrumb>
						</div> -->
				</div>
				<div style="display: flex;align-items: baseline;">
					<!-- <div style="padding-right: 15px; font-size: 14px;color: #8a93a2;">
							商户编号：{{menuStat.userInfo.merchantNo}} 欢迎您~
						</div> -->
					<div>
						<el-tooltip effect="dark"
							:content="$t('exchangeAll.ViewNationalHolidays')" placement="top">
							<i class="el-icon-date cursorcss" style="font-size: 24px;margin-right: 15px;"
								@click="calendarClick()"></i>
						</el-tooltip>
					</div>
					<el-dropdown
						style="float: right;cursor: pointer;color: #8a93a2; border-left: 1px solid #F1F3F5;padding-left: 15px;">
						<span>
							<i class="iconfont icon-yonghutouxiang yonghutouCss"
								style="margin-right: 10px"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<!-- <el-dropdown-item>首页</el-dropdown-item> -->
							<!-- <el-dropdown-item>修改密码</el-dropdown-item> -->
							<el-dropdown-item class="dropdowDiv">
								<div class="dropdowDiv-top">
									<i class="iconfont icon-yonghutouxiang yonghutouCss"
										style="margin-right: 10px;font-size: 36px; "></i>
									{{$t('h5Header.Merchantnumber')}}：<span
										v-if="menuStat.userInfo.merchantNo">{{menuStat.userInfo.merchantNo}}</span>
									<span v-else>{{menuStat.userInfo.agentNo}}</span>
								</div>
							</el-dropdown-item>
							<div class="dropdowDiv-box">
								<el-dropdown-item class="dropdowDiv-item">
									<div class="elupload">{{$t('h5Header.Merchantname')}}：</div><span
										v-if="menuStat.userInfo.companyName">{{menuStat.userInfo.companyName}}</span><span
										v-else>{{menuStat.userInfo.agentName}}</span>
								</el-dropdown-item>
								<el-dropdown-item class="dropdowDiv-item" v-if="menuStat.userInfo.userName">
									<div class="elupload">{{$t('h5Header.Operatorname')}}：</div>
									{{menuStat.userInfo.userName}}
								</el-dropdown-item>
								<el-dropdown-item class="dropdowDiv-item">
									<div class="elupload">{{$t('h5Header.Loginemail')}}：</div><span
										v-if="menuStat.userInfo.email">{{menuStat.userInfo.email}}</span><span
										v-else>{{menuStat.userInfo.contactEmail}}</span>
								</el-dropdown-item>
							</div>
							<el-dropdown-item @click.native="loginClick()">
								<div class="dropdowDiv-bot">{{$t("login.Logout")}}</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>

			</el-header>
			<!-- <div v-if="isCollapseequipment!='移动端'" class="midDiv" ref="pagemidDiv">
					<div v-if="brrs[0] != null" @click="pageLeft()" class="midDiv-divRight midDiv-pou" style="background: #FFFFFF;z-index:2">
						<i class="midDiv-icon el-icon-arrow-left" />
					</div>
					<div v-if="brrs[0]!=null" class="middle" ref="page" :class="slideRight?'slideRight':'slideLeft'">
						<div @click="navPush(item,index)" v-for="(item, index) in brrs" :key="index" :tab="item.title"
							:class="middle_act == item.title ? 'middle-action' : ''">
							<span>{{item.title }}</span>
							<i v-if="item.title!=middle_act&&item.title!='平台首页'" class="middle-icon el-icon-close"
								/>
							<i v-if="item.title==middle_act&&item.title!='平台首页'" class="middle-icon-action el-icon-error"
								@click.stop="log(index)" />
						</div>
					</div>
					<div v-if="brrs[0] != null" class="midDiv-right">
						<div class="midDiv-divRight midDiv-pou" @click="pageRight()">
							<i class="midDiv-icon el-icon-arrow-right"  />
						</div>
						<el-dropdown>
							<div class="midDiv-pou">
								<i class="midDiv-icon el-icon-setting"></i>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="pageClick('All')">
									关闭所有
								</el-dropdown-item>
								<el-dropdown-item  @click.native="pageClick('Other')">
									关闭其他
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div> -->
			<el-main class="container" style="overflow-y: scroll;">

				<router-view v-if="isRouterAlive"></router-view>
			</el-main>
		</el-container>

		<!-- 日历 -->
		<el-dialog :title="$t('exchangeAll.calendar')" :visible.sync="caleDialogVisible" width="532px">
			<div class="block Calendarselect">
				<el-date-picker v-model="date" type="month" size="mini" :placeholder="$t('LocalServices.Selectmonthtoview')"
					:clearable="false"></el-date-picker>
			</div>
			<el-calendar v-model="date">
				<template slot="dateCell" slot-scope="{date, data}">
					<div v-if="badgeDateTime.indexOf(data.day)=='-1'" class="Today-Css">
						<p :class="data.isSelected ? 'is-selected' : ''">
							{{ data.day.split('-').slice(2).join('-') }}
						</p>
					</div>
					<div v-else>
						<div v-for="(item,index) in badgeDateTime" :key="index" class="Today-Css">
							<el-tooltip class="item" v-if="data.day==item" effect="dark" placement="right-end" :offset="100">
								<div slot="content" style="width: 200px;">
									<span v-for="(i,num) in badgeDate[index]" :key="num">
										{{i.nameShow}}
									</span>
									{{$t('Tips.Duetothedelayintheentryofaccountsatrelevantsitestherewillalsobeadelayinthepaymenttocorrespondingregions')}}
								</div>
								<p :class="[data.isSelected ? 'is-selected' : '',badgeDateTime.indexOf(data.day)!='-1'?'Today-Css-redp':'']"
									:style="badgeDateTime.indexOf(data.day)!='-1'?'color:red':''">
									{{ data.day.split('-').slice(2).join('-') }}
								</p>
							</el-tooltip>
						</div>
					</div>
				</template>
			</el-calendar>
		</el-dialog>
<!--    证件到期提示-->
    <ExpirationReminder v-if="isdialogVisible" :title="$t('roleList.Tips')" :confirmButtonText="$t('radeReceColl.Uploadnow')" :dialogVisible="isdialogVisible" :handleClose="handleClose" @confirm="ExpirationClick" :showCancel="showCancel" :content="documentData" ></ExpirationReminder>
<!--    证件信息更新-->
    <DocumentChange v-if="DocumentChangeVisible" :title="$t('expire.Documentupdate')" :confirmButtonText="$t('myOperateDelList.Submit')" :dialogVisible="DocumentChangeVisible" :handleClose="DocumenthandleClose" @confirm="DocumentChangeClick" :content="documentData"></DocumentChange>
	</el-container>
</template>

<script>
	import Cookies from 'js-cookie'

	import dataLeftHide from "../common/dataLeftHide.json";
	import moment from 'moment'
  import ExpirationReminder from "@/components/expirationCom/ExpirationReminder.vue";
  import DocumentChange from "@/components/expirationCom/DocumentChange.vue";

	export default {
		name: 'Header',
    components: {DocumentChange, ExpirationReminder},
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			const that = this
			return {
				isCollapse: false,
				isCollapseequipment: '',
				path: '',
				iphonecss: '',
				administrator: '',
				data: [],
				brrs: [],
				middle_act: '', //当前导航显示的内容
				spanTitleAct: '', //二级菜单的样式
				slideRight: false, //导航栏滑动左右控制
				timeWidth: '', //监听当导航栏宽度
				timeWidthWhy: false, //监听是否可以滑动
				subDef: '',
				openeds: [],
				menuStat: '',
				isRouterAlive: true,
				caleDialogVisible: false,
				date: '',
				badgeDate: [],
				badgeDateTime: [],
				KiLinPayLogo: false,
				SystemLogo:{
					zhiyunG:false
				},
        // 证件到期提示mok
        showCancel: true,
        isdialogVisible: false,
      //   证件变更
        DocumentChangeVisible: false,
        documentData:[],
			}
		},
		beforeDestroy() {
			this.data = []
		},
		async created() {
      // 在这个位置 进行初始化一次
      this.getDocumentChanges()

			if (window.location.href.indexOf('kilinpay.1yunhui.com') != -1) {
				this.KiLinPayLogo = true
			}
			this.SystemLogo.zhiyunG = sessionStorage.getItem("SystemName")
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			if (flag) {
				this.isCollapse = false
				this.iphonecss = 'iphonecss'
				this.isCollapseequipment = "移动端"
			}
			// this.data=JSON.parse(sessionStorage.getItem('data'))
			if (this.administrator == '') {
				// this.getuserInfo()
			}
			if (!JSON.parse(sessionStorage.getItem('dataLogin'))) {
				this.$router.push('/login')
				return false
			}
			this.menuStat = JSON.parse(sessionStorage.getItem('dataLogin'))
			this.data = JSON.parse(sessionStorage.getItem('data'))
			if (!this.data) {
				await this.pageForRiskRelation()
			}

			this.brrs = JSON.parse(sessionStorage.getItem('brrs')) || []

		},
		mounted() {
        			if(this.brrs.some(item=>(item.href === location.pathname))){
        				this.subDef=location.pathname
        			}
        		},
		watch: {
		    $route(to,from){
            				if(this.brrs.some(item=>(item.href === to.path))){
            					this.subDef=to.path
            				}
            			},
		    date(val) {
		      const checkdate = moment(val).format('yyyy-MM-DD')
			  if(checkdate==moment(checkdate).startOf('month').format('yyyy-MM-DD')||checkdate==moment().format("YYYY-MM-DD")){
				  this.getHolidays(checkdate)
			  }
		    }
		  },
		methods: {
      // 证件更新
      DocumentChangeClick(){
        this.isdialogVisible = false
        this.DocumentChangeVisible = false
      },
      // 到期提示 立即上传
      ExpirationClick(data){
        // this.isdialogVisible = false
        this.DocumentChangeVisible = true
      },
      handleClose(){
        this.isdialogVisible = false
      },
      DocumenthandleClose(){
        this.DocumentChangeVisible = false
      },
      // 获取证件变更信息
      async getDocumentChanges(){
        const res = await  this.$http.get('/main/checkRiskDate',{
        })
        if(res.data.code === '0000'){
          // sessionStorage.setItem('documentData', JSON.stringify(res.data.data));
          if (res.data.data.error.length > 0){
            this.documentData=res.data.data.error
            this.showCancel = false
            this.isdialogVisible = true
          } else if(res.data.data.warn.length > 0){
            this.documentData=res.data.data.warn
            this.showCancel = true
            this.isdialogVisible = true
          }
        }else {
          this.$message.warning(res.data.message)
        }
      },
			// 日历弹出
			calendarClick() {
				this.date = moment().format("YYYY-MM-DD")
				this.caleDialogVisible = true
			},
			reload() {
				this.isRouterAlive = false
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			},
			pageClick(type) {
				if (this.brrs.length == 1 || this.brrs[0] == null) {
					return false
				}
				if (type == 'All') { //关闭所有
					this.brrs = [this.brrs[0]]
					sessionStorage.setItem('brrs', JSON.stringify(this.brrs))
					sessionStorage.setItem('middle_act', JSON.stringify(this.brrs[0].title))
					sessionStorage.setItem('middle_i', 0)
					for (let i of this.$router.options.routes[this.$router.options.routes.length - 1].children) {
						i.meta.keepAlive = true
					}
					if (this.middle_act != '平台首页') {
						if (this.brrs[0].fullPath != undefined && this.brrs[0].fullPath != this.brrs[0].href) {
							this.$router.push({
								path: this.brrs[0].fullPath
							})
						} else {
							this.$router.push({
								path: this.brrs[0].href
							})
						}
					} else {
						this.loadPage()
					}
				} else { //关闭其他
					for (let i of this.$router.options.routes[this.$router.options.routes.length - 1].children) {
						if (i.path != this.$route.path) {
							i.meta.keepAlive = true
						}
					}
					if (this.middle_act != '平台首页') {
						this.brrs = [this.brrs[0], this.brrs[sessionStorage.getItem('middle_i')]]
					} else {
						this.brrs = [this.brrs[0]]
						sessionStorage.setItem('middle_act', JSON.stringify(this.brrs[0].title))
						sessionStorage.setItem('middle_i', 0)
					}
					sessionStorage.setItem('brrs', JSON.stringify(this.brrs))
					// this.loadPage()
				}
			},
			log(e) {

				if (this.brrs.length == 1 || this.brrs[e].title == '平台首页') {
					return false
				}
				let optionRoutes = this.$router.options.routes.slice(-1)[0].children

				this.$common.TabFun(optionRoutes)
				// this.$route.meta.keepAlive=true
				// this.$store.commit('iskeepAlive', this.brrs[e].titleEn.split('/')[1]);
				if (this.brrs.length - 1 == e) {
					this.brrs.splice(e, 1);
					sessionStorage.setItem('brrs', JSON.stringify(this.brrs))
					this.navPush(this.brrs[this.brrs.length - 1], this.brrs.length - 1)
				} else {
					this.brrs.splice(e, 1);
					sessionStorage.setItem('brrs', JSON.stringify(this.brrs))
					this.navPush(this.brrs[e], e)
				}
			},
			navPush(items, index) {

				sessionStorage.setItem('middle_act', items.title)
				sessionStorage.setItem('middle_i', index)
				// console.log(this.menus)
				sessionStorage.setItem('children', JSON.stringify(items.children))
				if (items.fullPath != undefined && items.fullPath != items.href) {
					this.$router.push(items.fullPath)
				} else {
					this.$router.push(items.href)
				}
			},
			loadPage() { //刷新当前路由页面
				this.$router.push({
					path: "/loadPage"
				})
			},
			pageLeft() {
				if (!this.timeWidthWhy) {
					return false
				}
				if (this.$refs.pagemidDiv.clientWidth - 150 < this.$refs.page.clientWidth) { //可以滑动
					this.slideRight = false
				}
			},
			pageRight() {
				if (!this.timeWidthWhy) {
					return false
				}
				// this.$refs.pagemidDiv.clientWidth-150   导航栏总体宽度
				// this.$refs.page.clientWidth    导航栏内容宽度
				if (this.$refs.pagemidDiv.clientWidth - 150 < this.$refs.page.clientWidth) { //可以滑动
					this.slideRight = true
				}
			},
			div2() {
				if (this.isCollapseequipment == "移动端") {
					this.iphonecss = 'iphonecss'
				}
			},
			div1() {

			},
			isClick() {
				if (this.isCollapseequipment == '移动端') {
					if (this.iphonecss == 'iphonecssnone') {
						this.iphonecss = 'iphonecss'
					} else {
						this.iphonecss = 'iphonecssnone'
					}

				} else {
					this.isCollapse = !this.isCollapse
				}

			},
			goRouter(path) {
				this.path = path
				if (this.isCollapseequipment == "移动端") {
					this.iphonecss = 'iphonecss'
				}
				if (path.href == ' ') {
					return false
				}
				sessionStorage.setItem('children', JSON.stringify(path.children))
				let brrsTitle = []
				for (let i of this.brrs) {
					brrsTitle.push(i.title)
				}
				if (brrsTitle.indexOf(path.title) == '-1') {
					this.brrs.push(path)
				}

				sessionStorage.setItem('brrs', JSON.stringify(this.brrs))
				this.subDef = path.href
				this.$router.push(path.href)
			},
			loginClick() {
				this.getlogout()
			},
			// 退出登录
			getlogout() {
				this.$http
					.get('/login/logout')
					.then((res) => {
						if (res.data.code == "0000") {
							let email=JSON.parse(sessionStorage.getItem('dataLogin')).userInfo.email
							sessionStorage.clear()
							localStorage.removeItem('satoken')
							Cookies.remove('token')
							sessionStorage.setItem("accountNumber", email)
							this.$router.push('/login')
						} else {
							this.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
			},
			// 列表
			async pageForRiskRelation() {
				await this.$http
					.get('/login/getUserMenus')
					.then((res) => {
						if (res.data.code == "0000") {
							this.data = res.data.data
							let dataArr = []
							for (let i of this.data) {
								if (i.children.length > 0) {
									for (let s of i.children) {
										if (s.href != " " || s.href != "  ") {
											dataArr.push(s.href)
										}
									}
								} else {
									if (i.href != " " || i.href != "  ") {
										dataArr.push(i.href)
									}

								}
							}

							sessionStorage.setItem('data', JSON.stringify(this.data));
							let data = JSON.parse(sessionStorage.getItem('data'))
							if (data[0].href.indexOf('/InternetState') != '-1') {
								this.$router.push('/InternetState')
								return false
							}

						} else {
							this.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
			},
			// 获取用户信息
			getuserInfo() {
				this.$http
					.get('/user/userInfo')
					.then((res) => {
						if (res.data.code == "0000") {
							this.administrator = res.data.data.userName
						} else {
							this.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
			},
			// 日历接口
			getHolidays(index) {
				let startDate = moment(index).subtract(1, "months").format("YYYY-MM-DD")
				let endDate = moment(index).add(1, "months").format("YYYY-MM-DD")
				this.$http
					.post('/holiday/getHolidays', {
						'endDate': endDate,
						'startDate': startDate
					})
					.then((res) => {
						if (res.data.code == "0000") {
							this.badgeDate = Object.values(res.data.data)
							for (let i of this.badgeDate) {
								if(i.length>1){
									for (let j in i) {
										if(i.length-1==j){
											i[j].nameShow=i[j].name
										}else{
											i[j].nameShow=i[j].name+'/'
										}
									}
								}else{
									i[0].nameShow=i[0].name
								}
							}
							console.log(this.badgeDate)
							this.badgeDateTime = Object.keys(res.data.data).map(function myFunction(item) {
								let arrDate = item.split('');
								arrDate[4] = "-" + arrDate[4];
								arrDate[6] = "-" + arrDate[6]
								return arrDate.join().replace(/,/g, "");
							});

						} else {
							this.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
			}
		},

	}
</script>

<style lang="less" scoped>
	// /deep/ .el-calendar__button-group {
	// 	display: none;
	// }

	/deep/ .el-calendar-table td.is-today .Today-Css p {
		border: 1px solid;
		    border-radius: 50%;
		    width: 50px;
		    height: 50px;
		    margin: 0 auto;
	}

	.Today-Css {
		position: relative;
		overflow: hidden;

		&-redp::after {
			position: absolute;
			content: "";
			right: 24px;
			bottom: 5px;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: red;
		}
	}
    /deep/ .el-calendar__title{
		opacity: 0;
	}
	// /deep/ .el-dialog{
	// 	height: 433px;
	// 	background: transparent;
	// 	box-shadow: initial;
	// }
	/deep/ .el-calendar-table .el-calendar-day {
		height: 69px;
	}

	/deep/ .el-calendar-table td {
		text-align: center;
		line-height: 50px;
	}

	/deep/ .el-input--prefix .el-input__inner {
		padding-left: 30px !important;
	}

	.el-date-picker__editor-wrap {
		display: none;
	}

	.container::-webkit-scrollbar {
		display: none;
	}

	.container {
		-ms-overflow-style: none;
	}

	.container {
		overflow: -moz-scrollbars-none;
	}

	/deep/ .el-submenu__title,
	/deep/ .el-submenu__title i {
		color: #ffffff;
	}

	.el-menu-item {
		color: #ffffff;
		border-left: 4px solid #3A54B3;
	}

	.el-menu-item:hover {
		border-left: 4px solid #2D47AE;
		background-color: #2D47AE;
	}

	/deep/.el-menu>.el-menu-item {
		background: #233dab;
		border-left: 4px solid #233dab;
	}

	/deep/ .el-menu-item.is-active {
		color: #ffffff;
		background-color: #2D47AE;
		border-left: 4px solid #DEE3F6;
	}

	/deep/ .el-menu-item:hover {
		background: #2D47AE !important;
		color: #fff !important;
	}

	/deep/ .el-submenu__title:hover {
		background: #2D47AE !important;
		color: #fff !important;
	}

	/deep/ .el-menu {
		background-color: #3A54B3;
	}

	/deep/ .el-main {
		background-color: initial;
	}

	/deep/.el-submenu.is-active .el-submenu__title {
		background-color: #2D47AE;
		border-bottom: 1px solid #3A54B3;
	}

	/deep/.el-submenu__title {
		padding: 0 20px 0 23px !important;
	}

	.middle::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	.el-top {
		height: 60px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		border-bottom: 1px solid #2F4AAD;
	}

	.el-top-line {
		height: 1px;
		width: 100%;
		background: #4A63BF;
		opacity: 0.8;
		margin-bottom: 10px;
	}

	.middle {
		scrollbar-width: none;
		/* firefox */
		-ms-overflow-style: none;
		/* IE 10+ */
	}

	.middle {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #788CA2;
		line-height: 40px;
		white-space: nowrap;
		overflow-x: scroll;
		display: flex;

		&>div {
			padding: 0px 18px;
			display: flex;
			align-items: center;
			border-top: 2px solid transparent;
			cursor: pointer
		}

		&-icon {
			font-size: 10px;
			margin-left: 8px;
			color: #BFCBD9;

			&-action {
				font-size: 15px;
				margin-left: 8px;
				color: #3A54B3;
			}
		}

		&-action {
			background: #F6F8FC;
			border-top: 2px solid #3A54B3 !important;
		}
	}

	.midDiv {
		display: flex;
		align-items: center;
		position: relative;
		overflow: hidden;
		background: #ffffff;
		border-bottom: 1px solid #F1F3F5;
		border-top: 1px solid #F1F3F5;

		&>div {
			line-height: 40px;
		}

		&-divRight {
			border-right: 1px solid #E9F0F5;
		}

		&-pou {
			cursor: pointer;
		}

		&-right {
			display: flex;
			align-items: center;
			border-top: 2px solid #ffffff;
			background: #FFFFFF;
			position: absolute;
			right: 0;
		}

		&-icon {
			width: 46px;
			font-size: 14px;
			text-align: center;
		}
	}

	.el-header {
		background-color: #ffffff;
		color: #333333;
		line-height: 60px;
	}

	.el-aside {
		color: #333;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
		border: none;
	}

	/deep/ .el-breadcrumb__separator {
		color: #606266;
	}

	.iphonecss {

		display: none;
	}

	.iphonecssnone {
		position: fixed;
		z-index: 9999;
		width: 100vh;
	}

	.iphonecssherd {
		 z-index: 9990;
	}

	.icon-font {
		font-size: 20px;
		color: #333333;
	}

	.el-dropdown-menu {
		padding: 12px 0 0;
		box-shadow: 0px 0px 7px 0px rgba(0, 23, 108, 0.2);
	}

	.scrollY {
		height: 100vh;
		overflow-y: scroll;
	}

	.scrollY::-webkit-scrollbar {
		width: 0 !important
	}

	.scrollY {
		-ms-overflow-style: none;
	}

	.scrollY {
		overflow: -moz-scrollbars-none;
	}
	/deep/ .el-dialog__body{
		padding: 10px 20px 20px;
		position: relative;
		.el-calendar__header{
			padding-right: 0;
		}
		.el-calendar__body{
		padding: 0;
	}
	}
	.Calendarselect{
		position: absolute;
    top: 22px;
		z-index: 20;
	}
</style>
