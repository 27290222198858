import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ElementLocale from 'element-ui/lib/locale';
import router from './router/index'
import VueBus from 'vue-bus'
import http from "./api/http"
import Vuex from 'vuex'
import store from './store/store'
import axios from 'axios'
import Cookies from "js-cookie";
import './style/iconfont.css'
import './style/iconfont.js'
import Utils from './utils/index.js';
import './assets/font.css'
import ConfirmDialogPlugin from './components/confirm-dialog/confirm-dialog';
Vue.prototype.utils=Utils;

import VueAwesomeSwiper from 'vue-awesome-swiper';
import "swiper/dist/css/swiper.css";

Vue.use(VueAwesomeSwiper)

import './style/index.less'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import VueI18n from 'vue-i18n'
import Moment from "moment";
Vue.prototype.moment = Moment
// Vue.use(moment)
Vue.use(VueI18n)


import i18n from './common/i18n'

import emojiRegex from 'emoji-regex'; //npm install emoji-regexVue.directive('clearEmoij', {
Vue.directive('clearEmoij', {
	bind(el, binding, vnode, oldVnode) {
		const regex = emojiRegex();
		const obj = el.querySelectorAll('.el-input__inner,.el-textarea__inner')[0];
		let zclearNoNum = function(e) {
			if (e.target.composing) return;
			let match = regex.exec(obj.value); //也可以直接用正则表达式判断
			if (match) {
				/*for (let i=0;i<match.length;i++) {
				  obj.value = obj.value.replace(match[i],"");
				}*/
				obj.value = obj.value.replace(
					/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g,
					"");
				zclearNoNum(e);
			}
			// 触发v-model的更新
			obj.dispatchEvent(new Event('input'));
		}
		let zblur = function(e) {
			zclearNoNum(e);
			setTimeout(() => {
				let match = regex.exec(obj.value);
				if (match) {
					obj.value = obj.value.replace(
						/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g,
						"");
					zclearNoNum(e);
				}
				// 触发v-model的更新
				obj.dispatchEvent(new Event('change'));
			}, 50)
		}
		obj.onkeyup = zclearNoNum;
		obj.onblur = zblur;

		function onCompositionStart(e) {
			e.target.composing = true
		}

		function onCompositionEnd(e) {
			e.target.composing = false
			obj.dispatchEvent(new Event('input'));
		}
		// https://segmentfault.com/a/1190000009246058
		obj.addEventListener('compositionstart', onCompositionStart)
		obj.addEventListener('compositionend', onCompositionEnd)
	},
	update(el, binding, vnode, oldVnode) {}
})
Vue.directive('RegExp', {
  inserted(el,bindle) {
    const {value} = bindle
    const regList ={
		amount:common.Amount,
		price: common.PriceAmount,
		REG_ZERO_INTEGER:common.REG_ZERO_INTEGER_FUN,
		language_EN:common.language_EN,
		special:common.special,
		REG_EN:common.REG_EN_Fun,
		Remove_Spaces:common.RemoveSpaces,
		Remove_Chinese_Symbols:common.RemoveChineseAndChineseSymbols
    }
    const reg = regList[value]|| value
    if(reg){
      el.addEventListener('input', (e) => {
        let value = e.target.value;
        // 将输入值转换为数字类型
        e.target.value = reg(value)
        e.target.dispatchEvent(new Event('input')); // 更新v-model绑定的值
      });
    }
  }
});

import { Loading } from 'element-ui'
Vue.prototype.openLoading = function() {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  return loading;
}

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$axios = axios
import common from './common/common.js'
Vue.prototype.$common = common

Vue.use(ConfirmDialogPlugin);

new Vue({
	router,
	i18n,
	store,
	render: h => h(App),
}).$mount('#app')
